import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getCate } from "../features/cateSlice";
import logo from "../images/logo.svg";

function Header() {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [theme, setTheme] = useState(() => localStorage.getItem("theme") || "light");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const cate = useSelector((state) => state.cate.cate);

  // useEffect(() => {
  //   if (theme === "dark") {
  //     document.documentElement.classList.add("dark");
  //   } else {
  //     document.documentElement.classList.remove("dark");
  //   }
  //   localStorage.setItem("theme", theme);
  // }, [theme]);

  useEffect(() => {
    dispatch(getCate());
  }, [dispatch]);

  // const handleThemeSwitch = () => {
  //   setTheme((prevTheme) => (prevTheme === "dark" ? "light" : "dark"));
  // };

  const handleDropdownToggle = () => {
    setIsDropdownOpen((prevIsDropdownOpen) => !prevIsDropdownOpen);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };



  const location = useLocation();

  const scrollToAboutSection = () => {
    const section = document.getElementById('about-section');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleLinkClick = (event) => {
    event.preventDefault();
    navigate('/', { state: { scrollToAbout: true } });
  };

  // Scroll to about section on initial render if location state indicates
  useEffect(() => {
    if (location.state?.scrollToAbout) {
      scrollToAboutSection();
    }
  }, [location.state]);

  return (
    <header className="sticky">
      <div className="px-4 mx-auto max-w-screen-xl">
        <nav className="bg-white px-2 sm:px-4 py-2.5 border-black dark:border-white">
          <div className="container flex flex-wrap items-center justify-between mx-auto">
            <Link to="/" className="flex items-center">
              <img
                src={logo}
                className="h-full mr-3 sm:h-9"
                alt="Aqua Games Logo"
                style={{ height: "50px", width: "50px" }}
              />
              <span className="logotitle self-center text-xl font-semibold whitespace-nowrap">
                Aqua Games
              </span>
            </Link>
            <div className="flex">
              <button
                onClick={handleDropdownToggle}
                type="button"
                className="inline-flex items-center p-2 mr-2 right-0 text-sm rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-600"
                aria-expanded={isDropdownOpen ? "true" : "false"}
              >
                <span className="sr-only">Open menu</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <div
              className="items-center justify-between w-full md:flex md:w-auto  hidden lg:block"
              id="navbar-search"
            >
              <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white md:dark:bg-transparent">
                <li>
                  <Link
                    to="/"
                    onClick={handleDropdownClose}
                    className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    onClick={handleLinkClick}
                    className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                  >
                    About us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/allgames"
                    onClick={handleDropdownClose}
                    className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                  >
                    Games
                  </Link>
                </li>
                <li>
                  <Link
                    to="/career"
                    onClick={handleDropdownClose}
                    className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                  >
                    Careers
                  </Link>
                </li>
                <li>
                  <Link
                    to="/blogs"
                    onClick={handleDropdownClose}
                    className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                  >
                    Blogs
                  </Link>
                </li>
                {user && (
                  <li>
                    <Link
                      to="/dashboard"
                      onClick={handleDropdownClose}
                      className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                    >
                      Dashboard
                    </Link>
                  </li>
                )}
              </ul>
            </div>
            {isDropdownOpen && (
              <div
                className="items-center justify-between w-full md:flex md:w-auto"
                id="navbar-search"
              >
                <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white md:dark:bg-transparent">
                  <li>
                    <Link
                      to="/"
                      onClick={handleDropdownClose}
                      className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={handleLinkClick}
                      className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                    >
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/allgames"
                      onClick={handleDropdownClose}
                      className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                    >
                      Games
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/career"
                      onClick={handleDropdownClose}
                      className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                    >
                      Careers
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/blogs"
                      onClick={handleDropdownClose}
                      className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                    >
                      Blogs
                    </Link>
                  </li>
                  {user && (
                    <li>
                      <Link
                        to="/dashboard"
                        onClick={handleDropdownClose}
                        className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                      >
                        Dashboard
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
