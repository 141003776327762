import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  signIn } from '../features/authSlice';
import { useNavigate } from 'react-router-dom';
import logo from "../images/logo.svg"



function SignIn() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false)
  const { loading, error } = useSelector((state) => state.auth);
  let navigate = useNavigate();
  const handleSignIn = async (event) => {
    event.preventDefault();
    dispatch(signIn({ email, password, rememberMe, navigate }));
  };



  return (
    <section className="bg-gray-50 ">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a href="/comingsoon" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 ">
          <img
            src={logo}
            className="h-full mr-3 sm:h-9"
            alt="Aqua GamesLogo"
            style={{ height: "50px", width: "50px" }}
          />
          Aqua Games
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0  ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
              Sign in to your account
            </h1>
            <form onSubmit={handleSignIn} className="space-y-4 md:space-y-6" action="/comingsoon">
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Your email</label>
                <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Email..." required="" value={email}
                  onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 ">Password</label>
                <input type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" value={password}
                  onChange={(e) => setPassword(e.target.value)} />
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300  dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" value={rememberMe}
                      onChange={(e) => setRememberMe(e.target.value)} />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="remember" className=" dark:text-gray-300">Remember me</label>
                  </div>
                </div>
                {/* <a href="/comingsoon" className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</a> */}
              </div>
              <div className="w-full px-3">
                <button type="submit" className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none atbtn focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800" disabled={loading}>Sign in</button>
                {loading ? 'Signing In...' : null}
                {error && <p>{error.message}</p>}
              </div>
              {/* <p className="text-sm font-light  ">
                Don’t have an account yet? <a href="/Signup" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</a>
              </p> */}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignIn;