import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPosts } from '../features/postsSlice';
import { SliderPagination } from './SliderPagination';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import playStoreIcon from '../images/plat-store.png'
import AppStoreIcon from '../images/app-store.png'
export const Slider = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    dispatch(getPosts());
  }, [dispatch]);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const posts = useSelector((state) => state.posts.posts);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const featuredPosts = posts.filter((post) => post.category === 'Featured');
  const currentPosts = featuredPosts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % currentPosts.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentPosts]);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(-1); // Reset activeIndex when mouse leaves the slide
  };
  return (
    <>
      {
        currentPosts ? (
          <section className="bk_section_collection" >
            <div className="container flex mx-auto">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="flex items-center justify-center">
                  {currentPosts.length > 0 ? (
                    currentPosts.map((slide, index) => (
                      <>
                        <Helmet>
                          <link rel="preload" as="image" href={`${slide.imageUrl}`} imagesrcset={`${slide.imageUrl} 400w, ${slide.imageUrl} 800w`} imagesizes="50vw" />
                        </Helmet>
                        <div
                          className={`flex items-end justify-center slide ${index === activeIndex ? 'active' : ''}`}
                          key={index}
                          style={{ backgroundImage: `url(${slide.imageUrl})` }}
                          onClick={() => handleClick(index)}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <p className='m-3 w-full text-center'>{slide.title}
                            <div className="flex items-center justify-center">
                              {
                                slide.playstoreurl ?
                                  <a href={slide.playstoreurl} target='_blank' rel='noreferrer'>
                                    <img src={playStoreIcon} alt="play store icon" width={30} height={30} />
                                  </a>
                                  : null
                              }
                              {
                                slide.appstoreurl ?
                                  <a href={slide.appstoreurl} target='_blank'rel='noreferrer'>
                                    <img src={AppStoreIcon} className='ml-5' alt="app store icon" width={30} height={30} />
                                  </a>
                                  : null
                              }
                            </div>
                            {
                              slide.publishwith ?
                                <span className='text-sm'>Published with {slide.publishwith}</span>
                                : null
                            }
                          </p>

                        </div>
                      </>
                    ))
                  ) : (
                    <h3 className="mb-2 text-xl font-bold leading-tight text-gray-900 h-6 overflow-hidden   text-slate-900  mt-5 text-base font-medium tracking-tight">
                      Not Published  Yet
                    </h3>
                  )}
                </div>

                <div className="bk_game_collection">
                  <h2 data-aos="fade-up" data-aos-delay="150" className="aos-init aos-animate">
                    Game collection
                  </h2>
                  <p data-aos="fade-up" data-aos-delay="200" className="aos-init aos-animate">
                    We develop our fun games. The Development process begins with Ideas
                    then prototyping moved next to the game factory.
                  </p>
                  <div data-aos="fade-up" data-aos-delay="250" className="aos-init aos-animate">
                    <Link to="/allgames" className="btn btn_black btn_anim" target='_blank' >
                      All Games
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </Link>
                  </div>
                  <div data-aos="fade-up" data-aos-delay="300" className='mt-5'>
                    <SliderPagination
                      postsPerPage={postsPerPage}
                      totalPosts={featuredPosts.length}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section >
        ) : null
      }
    </>
  );
};
