import React from 'react'
import { BlogTables } from '../components/BlogTables'
import DashHeader from '../components/DashHeader'
import Sidebar from '../components/Sidebar'

export const ViewBlogsList = () => {
    return (
        <div className="">
            <DashHeader />
            <Sidebar />
            <div className="p-4 pt-20 sm:ml-64   ">
                <div className="p-4 ">
                    <BlogTables />
                </div>
            </div>
        </div>


    )
}
