import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmail } from '../../features/subscribeSlice';
import emailjs from 'emailjs-com';
import { uiActions } from '../../features/uiSlice';
export function EmailTable() {

    const subscribe = useSelector(state => state.subscribe.subscribe);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getEmail());
    }, [dispatch])

    const [selectedEmails, setSelectedEmails] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    function handleEmailSelection(email) {
        if (selectedEmails.includes(email)) {
            setSelectedEmails(selectedEmails.filter((e) => e !== email));
          
            setSelectAll(false);
        } else {
            setSelectedEmails([...selectedEmails, email]);
            if (selectedEmails.length + 1 === subscribe.length) {
                setSelectAll(true);
            }
        }
    }

    function handleSelectAll() {
        if (selectAll) {
            setSelectedEmails([]);
            setSelectAll(false);
        } else {
            setSelectedEmails(subscribe.map((email) => email.id));
            setSelectAll(true);

        }
    }
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(
            uiActions.showNotification({
                open: true,
                message: "Sending...",
                type: "warning",
            })
        );
        try {
            for (var i = 0; i < selectedEmails.length; i++) {
                const emailParams = {
                    to_email: selectedEmails[i],
                    subject: subject,
                    message: message
                };
               
    
                (function (i) {
                    emailjs.send('service_6o91zz7', 'template_zf2n7aj', emailParams, "KfyH5YD5BOpH4lstQ")
                        .then(function (response) {
                      
                      
                        }, function (error) {
                       
                        });
                })(i);
            }
            dispatch(
                uiActions.showNotification({
                    open: true,
                    message: "Sent successfully ",
                    type: "success",
                })
            );
        }
        catch (error) {
            dispatch(
                uiActions.showNotification({
                    open: true,
                    message: "error in sending ",
                    type: "error",
                })
            );
        }
    }
    


    return (
        <div className='attable'>
            <table className="w-full text-sm text-left  dark:text-gray-600">
                <thead className="text-xs text-gray-700 uppercase border-2  bg-gray-50  dark:text-gray-600">
                    <tr>
                        <th className="px-6 py-3 text-left w-full text-xs font-medium  uppercase tracking-wider">
                            <label htmlFor="selectAll" className="sr-only">
                                Select All
                            </label>
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    name="selectAll"
                                    id="selectAll"
                                    className="rounded-sm text-gray-600  focus:ring-offset-gray-800"
                                    onChange={handleSelectAll}
                                    checked={selectAll}
                                />
                                <span className="ml-2 dark:text-gray-200">Email</span>
                            </div>
                        </th>
                        {/* <th className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">
                            Name
                        </th> */}
                        {/* <th className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">
                            Status
                        </th> */}
                    </tr>
                </thead>
                <tbody className="bg-white border-2   ">
                    {subscribe.map((email) => (
                        <tr key={email.id}>
                            <td className="px-6 py-4 whitespace-nowrap">
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name={email.id}
                                        id={email.id}
                                        className="rounded-sm text-gray-600  focus:ring-offset-gray-800"
                                        onChange={() => handleEmailSelection(email.id)}
                                        checked={selectedEmails.includes(email.id)}
                                    />
                                    <label
                                        htmlFor={email.id}
                                        className="ml-2 block text-sm font-medium "
                                    >
                                        {email.id}
                                    </label>
                                </div>
                            </td>
                            {/* <td className="px-6 py-4 whitespace-nowrap">{email.name}</td> */}
                            {/* <td className="px-6 py-4 whitespace-nowrap">
                                <span
                                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${email.status === 'Active'
                                        ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100'
                                        : 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100'
                                        }`}
                                >
                                    {email.status}
                                </span>
                            </td> */}
                        </tr>
                    ))}
                </tbody>
            </table>
            <section className="bg-white ">
                <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 ">News Letter</h2>
                    <p className="mb-8 lg:mb-16 font-light text-center   sm:text-xl">Send Mail to Subscribe User</p>
                    <form action="/comingsoon" className="space-y-8" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="subject" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Subject</label>
                            <input type="text" id="subject" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Let us know how we can help you" required value={subject} onChange={(e) => setSubject(e.target.value)} />
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 ">Your message</label>
                            <textarea id="message" rows="6" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Leave a comment..." value={message} onChange={(e) => setMessage(e.target.value)} ></textarea>
                        </div>
                        <button type="submit" className="atbtn py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-blue sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Send message</button>
                    </form>
                </div>
            </section>
        </div>
    )
}