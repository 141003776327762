import React from 'react'

export const PaginationBlogs = ({ postsPerPage, totalPosts, paginate, currentPage }) => {

    const totalPages = Math.ceil(totalPosts / postsPerPage);

    if (totalPages <= 1) {
        return null;
    }

    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;

    const handleClick = (page) => {
        if (page === 'dots1' || page === 'dots2') {
            return null;
        }
        paginate(page);

    };

    const renderPageLink = (page, label) => {
        return (
            <button
                onClick={() => handleClick(page)}
                className={`${currentPage === page
                    ? 'font-medium text-white bg-gray-700 border border-gray-200  toggle-dark-state-example hover:bg-gray-100 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500 dark:bg-gray-500 focus:outline-none  dark:border-gray-600  '
                    : 'font-medium text-gray-700 bg-white border border-gray-200  toggle-dark-state-example hover:bg-gray-100 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500  focus:outline-none  dark:border-gray-600  '
                    } border border-gray-300 px-4 py-2 mx-1  bg-gray-900 text-sm font-medium focus:outline-none hover:bg-gray-800 `}
            >
                {label}
            </button>
        );
    };

    const paginationNumbers = () => {
        const links = [];

        // Show first page link
        links.push(renderPageLink(1, 1));

        // Show page numbers
        let min = Math.max(currentPage - 1, 2);
        let max = Math.min(currentPage + 2, totalPages - 1);
        if (max - min < 3) {
            if (currentPage < 3) {
                max = Math.min(currentPage + 3, totalPages - 1);
            } else {
                min = Math.max(currentPage - 3, 2);
            }
        }
        if (min > 2) {
            links.push(renderPageLink('dots1', '...'));
        }
        for (let i = min; i <= max; i++) {
            links.push(renderPageLink(i, i));
        }
        if (max < totalPages - 1) {
            links.push(renderPageLink('dots2', '...'));
        }

        // Show last page link
        if (totalPages > 1) {
            links.push(renderPageLink(totalPages, totalPages));
        }

        return links;
    };


    return (
        <nav key={currentPage} className="flex items-center mt-5  px-4 py-3 sm:px-6   " aria-label="Pagination">
            <div className="flex-1 flex justify-center lg:justify-end ">
                <div>
                    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                        {isFirstPage ? (
                            <button
                                disabled
                                className="relative inline-flex items-center px-2 py-2 rounded-l-md font-medium text-gray-700 bg-white border border-gray-200  toggle-dark-state-example hover:bg-gray-800 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500  focus:outline-none  dark:border-gray-600   "
                            >
                                <span className="sr-only">Previous</span>
                                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                </svg>
                            </button>
                        ) : (
                            <button
                                onClick={() => handleClick(currentPage - 1)}
                                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-gray-900 text-sm font-medium text-white  hover:bg-gray-800 focus:z-10 focus:outline-none  "
                            >
                                <span className="sr-only">Previous</span>
                                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                </svg>
                            </button>
                        )}
                        {paginationNumbers()}
                        {isLastPage ? (
                            <button
                                disabled
                                className="relative inline-flex items-center px-2 py-2 rounded-r-md font-medium text-gray-700 bg-white border border-gray-200  toggle-dark-state-example hover:bg-gray-800 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500  focus:outline-none  dark:border-gray-600  dark:hover:bg-gray-600"
                            >
                                <span className="sr-only">Next</span>
                                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                </svg>
                            </button>
                        ) : (
                            <button
                                onClick={() => handleClick(currentPage + 1)}
                                className="relative inline-flex items-center px-2 py-2 bg-gray-900 text-white rounded-r-md border border-gray-300  text-sm font-medium  hover:bg-gray-800 focus:z-10 focus:outline-none  text-white"
                            >
                                <span className="sr-only">Next</span>
                                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                </svg>
                            </button>
                        )}
                    </nav>
                </div>
            </div>
        </nav>
    )
}
