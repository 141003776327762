import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signOutUser } from "../../features/authSlice";
import { useDispatch } from "react-redux";

const Sidebar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleSignOut = async (event) => {
        event.preventDefault();
        dispatch(signOutUser(navigate));
    };

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const handleSidebarToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    return (
        <div className="atsidebar relative border-gray-900  bg-slate-800  ">
            <button
                onClick={handleSidebarToggle}
                aria-controls="default-sidebar"
                type="button"
                className="inline-flex items-center p-1 bg-gray-700 text-sm text-gray-100 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none  text-gray-100 flex fixed left-1 top-16 z-40 focus:ring-2 focus:ring-gray-200   dark:focus:ring-gray-600"
            >
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>
            <aside
                id="default-sidebar"
                className={`fixed atsidebar left-0 z-40 w-64 h-screen transition-transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full sm:translate-x-0'
                    }`}
                aria-label="Sidebar"
            >
                <div className="h-full px-3 py-4 mt-2 overflow-y-auto bg-slate-800 ">
                    {isSidebarOpen ? <button
                        onClick={handleSidebarToggle}
                        aria-controls="default-sidebar"
                        type="button"
                        className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-900 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none    focus:ring-2 focus:ring-gray-200   dark:focus:ring-gray-600"
                    >
                        <span className="sr-only">Open sidebar</span>
                        <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path clipRule="evenodd" fillRule="evenodd" d="M14.15 5.86a.5.5 0 00-.71 0L10 9.29 6.56 5.86a.5.5 0 10-.71.71L9.29 10l-3.43 3.43a.5.5 0 00.71.71L10 10.71l3.43 3.43a.5.5 0 00.71-.71L10.71 10l3.44-3.44a.5.5 0 000-.7z" />
                        </svg>
                    </button> : null}
                    <ul className="space-y-2 font-medium">
                        <li>
                            <Link to="/" className="flex items-center p-2 text-white rounded-lg  hover:bg-gray-100 hover:text-slate-800 ">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6  transition duration-75  group-hover:text-gray-900 dark:group-hover:text-white">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                </svg>
                                <span className="ml-3">Home</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/dashboard" className="flex items-center p-2 text-white rounded-lg  hover:bg-gray-100 hover:text-slate-800 ">
                                <svg aria-hidden="true" className="w-6 h-6  transition duration-75  group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path></svg>
                                <span className="ml-3">Dashboard</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/blog/create" className="flex items-center p-2 text-white rounded-lg  hover:bg-gray-100 hover:text-slate-800 ">
                                <svg aria-hidden="true" className="flex-shrink-0 w-6 h-6  transition duration-75  group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path></svg>
                                <span className="flex-1 ml-3 whitespace-nowrap">Create</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/emailtable" className="flex items-center p-2 text-white rounded-lg  hover:bg-gray-100 hover:text-slate-800 ">
                                <svg aria-hidden="true" className="flex-shrink-0 w-6 h-6  transition duration-75  group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clipRule="evenodd"></path></svg>
                                <span className="flex-1 ml-3 whitespace-nowrap">Send Mail</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/viewblog" className="flex items-center p-2 text-white rounded-lg  hover:bg-gray-100 hover:text-slate-800 ">
                                <svg aria-hidden="true" className="flex-shrink-0 w-6 h-6  transition duration-75  group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z" clipRule="evenodd"></path></svg>
                                <span className="flex-1 ml-3 whitespace-nowrap">List</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/appliedjobs" className="flex items-center p-2 text-white rounded-lg  hover:bg-gray-100 hover:text-slate-800 ">
                                <svg aria-hidden="true" className="flex-shrink-0 w-6 h-6  transition duration-75  group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z" clipRule="evenodd"></path></svg>
                                <span className="flex-1 ml-3 whitespace-nowrap">Applied Jobs List</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/addCategory" className="flex items-center p-2 text-white rounded-lg  hover:bg-gray-100 hover:text-slate-800 ">
                                <svg fill="none" className="flex-shrink-0 w-6 h-6  transition duration-75  group-hover:text-gray-900 dark:group-hover:text-white" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                </svg>
                                <span className="flex-1 ml-3 whitespace-nowrap">Add Category</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/addPartner" className="flex items-center p-2 text-white rounded-lg  hover:bg-gray-100 hover:text-slate-800 ">
                                <svg fill="none" className="flex-shrink-0 w-6 h-6  transition duration-75  group-hover:text-gray-900 dark:group-hover:text-white" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                </svg>
                                <span className="flex-1 ml-3 whitespace-nowrap">Add Partner</span>
                            </Link>
                        </li>
                        <li>
                            <Link onClick={handleSignOut} className="flex items-center p-2 text-white rounded-lg  hover:bg-gray-100 hover:text-slate-800 ">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="flex-shrink-0 w-6 h-6  transition duration-75  group-hover:text-gray-900 dark:group-hover:text-white">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                                </svg>

                                <span className="flex-1 ml-3 whitespace-nowrap">Sign Out</span>
                            </Link >
                        </li>



                    </ul>
                </div>
            </aside>
        </div>

    );
};

export default Sidebar;
