import React from 'react'
import DashHeader from '../components/DashHeader'
import Sidebar from '../components/Sidebar'
import { AppliedJobsTable } from '../components/AppliedJobsTable'

export const ViewAppliedJobsList = () => {
    return (
        <div className="">
            <DashHeader />
            <Sidebar />
            <div className="p-4 pt-20 sm:ml-64   ">
                <div className="p-4 ">
                    <AppliedJobsTable />
                </div>
            </div>
        </div>


    )
}
