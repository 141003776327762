import React, { useEffect, useLayoutEffect, useState } from "react";
import "aos/dist/aos.css";
import "./css/style.css";
import Home from "./pages/Home";
// import Aos from "aos";
import SignIn from "./admin/SignIn";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Dashboard from "./admin/pages/Dashboard";
import { BlogCards } from "./partials/BlogCards";
import { SingleBlog } from "./pages/SingleBlog";
import "flowbite";
import { ViewBlogsList } from "./admin/pages/ViewBlogsList";
import UpdateBlog from "./admin/pages/UpdateBlog";
import { Spinner } from "./admin/components/Spinner";
import { CategoryFilterBlogs } from "./admin/pages/CategoryFilterBlogs";
import { SearchBlogs } from "./admin/pages/SearchBlogs";
import { NewsLetterEmail } from "./admin/pages/NewsLetterEmail";
import { Errorpage } from "./pages/Errorpage";
import { NetwortCheck } from "./pages/NetwortCheck";
import { incrementVisitorCount } from "./features/incrementVisitorCount ";
import { VisitorCount } from "./admin/pages/VisitorCount";
import { getUserDataFromStorage, setUser } from "./features/authSlice";
import { AddCategory } from "./admin/pages/AddCategory";
import { Blogs } from "./pages/Blog";
import AOS from 'aos';
import { Career } from "./pages/Career";
import { SingleCareerDetails } from "./pages/SingleCareerDetails";
import { ViewAppliedJobsList } from "./admin/pages/ViewAppliedJobsList";
import { AllGames } from "./pages/AllGames";
import { TermandCondition } from "./pages/TermandCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SuperAdmin from "./admin/SuperAdmin";
import { AddPartner } from "./admin/pages/AddPartner";
import { Helmet } from "react-helmet";
import Notification from "./admin/components/Notification";
function App() {
  const { user } = useSelector((state) => state.auth);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const session = getUserDataFromStorage();
  useLayoutEffect(() => {
    AOS.init({
      duration: 200,
      easing: "ease-out-cubic",
    });
    
  }, []);
  useEffect(() => {
    if (session) {
      setUser(null)
    }
    function handleOnlineStatus() {
      setIsOnline(window.navigator.onLine);
    }

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };



  }, [session]);

  function handleRetry() {
    setIsOnline(window.navigator.onLine);
  }
  const notification = useSelector((state) => state.ui.notification);
  const spinner = useSelector((state) => state.si.spinner);
  const dispatch = useDispatch();
  const canonicalURL = window.location.href;
  useEffect(() => {
    dispatch(incrementVisitorCount());
  }, [dispatch]);
  return (
    <>
      <div>
        {isOnline ? (
          <div>
            {notification && (
              <Notification
                message={notification.message}
                type={notification.type}
              />
            )}
            {spinner && <Spinner />}
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/BlogCards" element={<BlogCards />} />
                <Route path="/blog/:url" element={<SingleBlog />} />
                <Route path="/career/:url" element={<SingleCareerDetails />} />
                <Route path="/career" element={<Career />} />
                <Route path="/allgames" element={<AllGames />} />
                <Route path="/term-and-conditions" element={<TermandCondition />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route
                  path="/blogcategory/:category"
                  element={<CategoryFilterBlogs />}
                />
                <Route path="/update/:postId/:mode" element={user ? <UpdateBlog /> : <SignIn />} />
                <Route path="/viewblog" element={user ? <ViewBlogsList /> : <SignIn />} />
                <Route path="/appliedjobs" element={user ? <ViewAppliedJobsList /> : <SignIn />} />
                <Route path="/emailtable" element={user ? <NewsLetterEmail /> : <SignIn />} />
                <Route path="/addCategory" element={user ? <AddCategory /> : <SignIn />} />
                <Route path="/addPartner" element={user ? <AddPartner /> : <SignIn />} />
                <Route path="/Admin/Signup" element={<SuperAdmin />} />
                <Route path="/SearchBlogs" element={<SearchBlogs />} />
                <Route path="*" element={<Errorpage />} />
                <Route path="/blog/create" element={user ? <Dashboard /> : <SignIn />} />
                <Route path="/dashboard" element={user ? <VisitorCount /> : <SignIn />} />
              </Routes>
            </BrowserRouter>
            <Helmet>
              <link rel="canonical" data-rh="true" href={canonicalURL} />
            </Helmet>

          </div>
        ) : (
          <NetwortCheck handleRetry={handleRetry} />
        )}
      </div>

      {/* <SingleBlog/> */}
    </>
  );
}

export default App;
