import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deletePost, getPosts } from '../../features/postsSlice';
import dateFormat from "dateformat";
import { Link } from 'react-router-dom';
export const BlogTables = () => {
    const posts = useSelector(state => state.posts.posts);
    const { user } = useSelector((state) => state.auth);
    const [gId, setGId] = useState(null);
    const [mode, setMode] = useState('blog');
    const dispatch = useDispatch();
    useEffect(() => {
        if (!user || !user.userData || !user.userData.uid) {
            window.location.reload();
        } else {
            setGId(user.userData.uid);
            dispatch(getPosts());
        }
    }, [dispatch, user]);

    const handleDelete = useCallback(
        (did) => async () => {
            dispatch(deletePost(did));
        },
        [dispatch]
    );
    const handleModeChange = (e) => {
        setMode(e.target.value);
    };
    return (
        <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <div className="overflow-x-auto">
                <div className="align-middle inline-block min-w-full shadow-lg rounded-lg overflow-hidden">
                    <div className="mb-6">
                        <label htmlFor="mode" className="block mb-2 text-sm font-medium text-gray-900 ">Mode</label>
                        <select id="mode" value={mode} onChange={handleModeChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option value="blog">Blog Mode</option>
                            <option value="career">Career Mode</option>
                        </select>
                    </div>
                    <table className="w-full text-sm text-left  ">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
                            <tr>
                                <th className="px-6 py-3 border-b border-gray-600 text-left text-xs leading-4 font-medium  uppercase tracking-wider">Title</th>
                                <th className="px-6 py-3 border-b border-gray-600 text-left text-xs leading-4 font-medium  uppercase tracking-wider">Category</th>
                                <th className="px-6 py-3 border-b border-gray-600 text-left text-xs leading-4 font-medium  uppercase tracking-wider">Mode</th>
                                <th className="px-6 py-3 border-b border-gray-600 text-left text-xs leading-4 font-medium  uppercase tracking-wider">blogurl</th>
                                <th className="px-6 py-3 border-b border-gray-600 text-left text-xs leading-4 font-medium  uppercase tracking-wider">Date</th>
                                <th className="px-6 py-3 border-b border-gray-600 text-left text-xs leading-4 font-medium  uppercase tracking-wider">Update</th>
                                <th className="px-6 py-3 border-b border-gray-600 text-left text-xs leading-4 font-medium  uppercase tracking-wider">Delete</th>
                                <th className="px-6 py-3 border-b border-gray-600 text-left text-xs leading-4 font-medium  uppercase tracking-wider">view</th>

                            </tr>
                        </thead>
                        <tbody >

                            {posts.map(i => (
                                i.userid === gId ? (
                                    i.mode === mode && (
                                        <tr className="text-gray-900 bg-white border-b   text-white" key={i.id}>
                                            <td className="px-6 py-4 ">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0 h-10 w-10">
                                                        <img className="h-10 w-10 rounded-full" src={i.imageUrl} alt={i.altname} />
                                                    </div>
                                                    <div className="ml-4">
                                                        <div className="text-sm leading-5 font-medium text-gray-900  ">{i.title}</div>
                                                        {/* <div className="text-xs leading-4 text-gray-400">Web Developer</div> */}
                                                    </div>
                                                </div>
                                            </td>

                                            <td className="px-6 py-4 ">
                                                <div className="text-sm leading-5 text-gray-900  ">{i.category}</div>
                                                {/* <div className="text-xs leading-4 text-gray-400">Senior</div> */}
                                            </td>
                                            <td className="px-6 py-4 ">
                                                <div className="text-sm leading-5 text-gray-900  ">{i.mode}</div>
                                                {/* <div className="text-xs leading-4 text-gray-400">Senior</div> */}
                                            </td>
                                            <td className="px-6 py-4  text-sm leading-5 text-gray-400">{i.blogurl}</td>
                                            {/* <td className="px-6 py-4  text-sm leading-5 text-gray-400">{i.tags}</td> */}
                                            <td className="px-6 py-4  text-sm leading-5 text-gray-400">{dateFormat(i.createdAt, "mmmm dS, yyyy")}</td>
                                            <td className="px-6 py-4 ">
                                                <Link to={`/update/${i.id}/${mode}`}><span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-400 text-gray-800">Update</span></Link>
                                            </td>
                                            <td className="px-6 py-4 ">
                                                <button className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-400 text-gray-800" onClick={handleDelete(i.id)}>Delete</button>
                                            </td>
                                            <td className="px-6 py-4 ">
                                                <Link to={`/blog/${i.blogurl}`}><span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-400 text-gray-800">Fullview</span></Link>
                                            </td>
                                        </tr>
                                    )) : null
                            ))}



                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}
