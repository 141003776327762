import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getPosts } from '../features/postsSlice';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import dateFormat from 'dateformat';
import { PaginationBlogs } from '../partials/PaginationBlogs';
export const Blogs = () => {


    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(12);

    useEffect(() => {
        dispatch(getPosts());
    }, [dispatch]);

    const posts = useSelector((state) => state.posts.posts);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const filterPost = posts.filter((post) => post.category === 'Blog');
    const currentPosts = filterPost.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);



    return (
        <div>
            <Header />
            <div>
                {currentPosts ?
                    <aside aria-label="Related articles" className="pt-4 py-8 lg:py-24 bg-gray-50 ">
                        <div className="px-4 mx-auto max-w-screen-xl ">
                            {/* <div className="relative">
                                <button id="dropdownDefaultButton"
                                    onClick={toggleDropdown} className="btn btn_black btn_anim catebtn">Category <span></span><span></span><span></span><span></span>                                    <svg
                                        className={`w-4 h-4 ml-2 ${isOpen ? 'transform rotate-180' : ''}`}
                                        aria-hidden="true"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                    </svg></button>
                                <div
                                    id="dropdown"
                                    className={`absolute z-10 ${isOpen ? '' : 'hidden'} bg-white divide-y divide-gray-100 rounded-lg  shadow `}
                                >
                                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                                        {cate.map(c => (
                                            c.mode === "blog" &&
                                            (
                                                <li key={c.id}>

                                                    <Link to={`/blogcategory/${c.cate}`} className="block px-4 py-2 hover:bg-gray-100 w-full">{c.cate}</Link></li>
                                            )
                                        ))}
                                    </ul>
                                </div>
                            </div> */}
                            <h2 className="mt-8 mb-8 text-2xl font-bold text-gray-900 ">Blogs</h2>
                            <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-4">
                                {currentPosts.map(i => (
                                    currentPosts ?
                                        (
                                            <Link to={`/blog/${i.blogurl}`} key={i.id} className="">
                                                <div className="card">
                                                    <div className="card__header">
                                                        <img src={i.imageUrl} alt={i.altname} className="card__image" width="600" />
                                                    </div>
                                                    <div className="card__body">
                                                        <h4>{i.title}</h4>
                                                        <p className=" overflow-hidden  mt-auto flex-grow line-clamp-3" dangerouslySetInnerHTML={{ __html: i.content }}></p>
                                                    </div>
                                                    <div className="card__footer">
                                                        <div className="user">
                                                            <div className="user__info">
                                                                <small> {dateFormat(
                                                                    i.createdAt,
                                                                    "mmmm dS, yyyy"
                                                                )}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        ) : (
                                            null
                                        )
                                ))}
                            </div>
                            <PaginationBlogs
                                postsPerPage={postsPerPage}
                                totalPosts={filterPost.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />

                        </div>
                    </aside > : <h2>No Blog Posted Yet</h2>}
            </div >
            <Footer />
        </div >
    )
}
