import React from "react";

import Header from "../partials/Header";
import HeroHome from "../partials/HeroHome";
import Newsletter from "../partials/Newsletter";
import Footer from "../partials/Footer";
import { BlogCards } from "../partials/BlogCards";
import { Slider } from "../partials/Slider";
import { JoinPage } from "../partials/JoinPage";
import { Aboutus } from "./Aboutus";
import { Contactus } from "./Contactus";
import { Partners } from "../partials/Partners";


function Home({ handleThemeSwitch }) {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header handleThemeSwitch={handleThemeSwitch} />
      <main className="flex-grow">
        <HeroHome />
        <Slider />
        <JoinPage />
        <BlogCards />
        <Partners/>
        <Aboutus />
        <Newsletter />
        <Contactus/>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
