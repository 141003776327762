import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { searchBlogs } from '../../features/postsSlice';
import Footer from '../../partials/Footer';
import Header from '../../partials/Header'
import { PaginationBlogs } from '../../partials/PaginationBlogs';

export const SearchBlogs = () => {
    const location = useLocation();
    const searchTerm = new URLSearchParams(location.search).get('q');
    const dispatch = useDispatch();
    const searchResults = useSelector(state => state.posts.searchResults);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(4);

    useEffect(() => {
        dispatch(searchBlogs(searchTerm));
    }, [dispatch, searchTerm]);
    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = searchResults.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">
            <Header />
            <main className="flex-grow  text-base">
                <div className="px-4 mx-auto mb-4 max-w-screen-xl">
                    <h2 className="mt-8 mb-8 text-2xl font-bold text-gray-900 ">Blogs</h2>
                    <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-4">
                        {
                            currentPosts ?
                                currentPosts.map(i => (
                                    <Link to={`/blog/${i.blogurl}`} key={i.id} className="p-4 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100    h-full flex flex-col">
                                        <img src={i.imageUrl} className="mb-5 rounded-lg h-2/4 w-full" alt={i.altname} />

                                        <h2 className="mb-2 text-xl font-bold leading-tight text-gray-900 h-6 overflow-hidden   text-slate-900  mt-auto text-base font-medium tracking-tight">
                                            {i.title}
                                        </h2>
                                        <p
                                            className=" font-light  overflow-hidden  text-slate-500 dark:text-slate-400 mt-auto flex-grow line-clamp-1"
                                            dangerouslySetInnerHTML={{ __html: i.content }}
                                        ></p>

                                        <span className="inline-flex items-center mt-2 text-base font-medium tracking-tight ">
                                            Read in 2 minutes
                                        </span>
                                    </Link>
                                )
                                ) : (
                                    <h3 className="mb-2 text-xl font-bold leading-tight text-gray-900 h-6 overflow-hidden   text-slate-900  mt-5 text-base font-medium tracking-tight">
                                        Not Published Blogs Yet
                                    </h3>
                                )

                        }
                    </div>
                    <PaginationBlogs
                        postsPerPage={postsPerPage}
                        totalPosts={searchResults.length}
                        paginate={paginate}
                        currentPage={currentPage}
                    />

                </div>
            </main>
            <Footer />
        </div>
    )
}
