// src/features/posts/postsSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { siActions, } from './SpinnerSlice';
import { uiActions } from './uiSlice';
const initialState = {
    posts: [],
    jobs: [],
    isLoading: false,
    error: null,
    cate: [],
    post: null,
    job: null,
    searchResults: [],
};
export const getCateSuccess = cate => ({
    type: 'posts/getCateSuccess',
    payload: cate
});
export const getPostsSuccess = posts => ({
    type: 'posts/getPostsSuccess',
    payload: posts
});
export const getJobsSuccess = jobs => ({
    type: 'posts/getJobsSuccess',
    payload: jobs
});
export const deleteJobSuccess = job => ({
    type: 'posts/deleteJobSuccess',
    payload: job,
});
export const getPostsByCategorySuccess = posts => ({
    type: 'posts/getPostsByCategorySuccess',
    payload: posts
});


export const getPostByUrlSuccess = post => ({
    type: 'posts/getPostByUrlSuccess',
    payload: post,
});
export const getPostByCategorySuccess = posts => ({
    type: 'posts/getPostByCategorySuccess',
    payload: posts,
});

export const getPostByIdSuccess = post => ({
    type: 'posts/getPostByIdSuccess',
    payload: post,
});
export const updatePostSuccess = post => ({
    type: 'posts/updatePostSuccess',
    payload: post,
});
export const deletePostSuccess = post => ({
    type: 'posts/deletePostSuccess',
    payload: post,
});
export const searchBlogsSuccess = posts => ({
    type: 'posts/searchBlogsSuccess',
    payload: posts
});


const postsSlice = createSlice({
    name: 'posts',
    initialState,
    reducers: {
        startLoading: state => {
            state.isLoading = true;
        },
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        createPostSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.posts.push(action.payload);
        },
        createJobsSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.jobs.push(action.payload);
        },
        getPostsSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.posts = action.payload;
        },
        getJobsSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.jobs = action.payload;
        },
        getPostsByCategorySuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.posts = action.payload;
        },
        getPostByUrlSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.post = action.payload;
        },
        getPostByIdSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.post = action.payload;
        },
        updatePostSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.posts = state.posts.map(post => post.id === action.payload.id ? action.payload : post);
            state.post = action.payload;
        },
        getPostByCategorySuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.posts = action.payload;
        },
        deletePostSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.post = state.posts.filter(post => post.id !== action.payload);
        },
        deleteJobSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.job = state.job.filter(job => job.id !== action.payload);
        },
        searchBlogsSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.searchResults = action.payload;
        },
        createCateSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.cate.push(action.payload);
        },
        getCateSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.cate = action.payload;
        },

    },
});
export const createPost = (altname, title, blogurl, category, content, imageUrl, metatitle, metadescription, metakeyword, navigate, userid, profileImage, jobTitle, userName, sType,playstoreurl, appstoreurl,publishwith, mode) => async dispatch => {
    dispatch(startLoading());
    dispatch(
        uiActions.showNotification({
            open: true,
            message: "sending Request",
            type: "warning",
        })
    );
    try {
        // Check if blogurl already exists in Firestore
        const querySnapshot = await getDocs(query(collection(db, "posts"), where("blogurl", "==", blogurl)));
        if (!querySnapshot.empty) {
            dispatch(
                uiActions.showNotification({
                    open: true,
                    message: `${mode} URL already exists.`,
                    type: "error",
                })
            );
            throw new Error(`${mode} URL already exists.`);
        }
        // If blogurl doesn't exist, add the new post to Firestore
        const newPostRef = await addDoc(collection(db, "posts"), {
            altname,
            title,
            blogurl,
            category,
            content,
            imageUrl,
            metatitle,
            metadescription,
            metakeyword,
            userid,
            profileImage,
            jobTitle, userName,
            sType,playstoreurl, appstoreurl,publishwith, mode,
            createdAt: Date.now(),
        });
        const newPost = {
            id: newPostRef.id,
            altname,
            title,
            blogurl,
            category,
            content,
            imageUrl,
            metatitle,
            metadescription,
            metakeyword,
            userid,
            profileImage,
            jobTitle, userName,
            sType,playstoreurl, appstoreurl,publishwith, mode,
            createdAt: Date.now()
        };
        dispatch(createPostSuccess(newPost));

        dispatch(
            uiActions.showNotification({
                open: true,
                message: "sent to database successfully ",
                type: "success",
            })
        );

        navigate(`/${mode}/` + newPost.blogurl);
    } catch (error) {
        dispatch(hasError(error.message));
        dispatch(
            uiActions.showNotification({
                open: true,
                message: error.message,
                type: "error",
            })
        );
    }

};
export const createJob = (email, name, number, message, url, imageUrl, userid) => async dispatch => {
    dispatch(startLoading());
    dispatch(
        uiActions.showNotification({
            open: true,
            message: "Sending Request",
            type: "warning",
        })
    );
    try {
        const newPostRef = await addDoc(collection(db, "appliedforjobs"), {
            email, name, number, message, url, imageUrl, userid,
            createdAt: Date.now(),
        });
        const newPost = {
            id: newPostRef.id,
            email, name, number, message, url, imageUrl, userid,
            createdAt: Date.now(),
        };
        dispatch(createJobsSuccess(newPost));

        dispatch(
            uiActions.showNotification({
                open: true,
                message: "Applied Successfully ",
                type: "success",
            })
        );

    } catch (error) {
        dispatch(hasError(error.message));
        dispatch(
            uiActions.showNotification({
                open: true,
                message: error.message,
                type: "error",
            })
        );
    }

};

export const getPosts = (mode) => async dispatch => {
    dispatch(startLoading());
    dispatch(siActions.showSpinner({ open2: true }));
    // if (mode === "career") {
    //     try {
    //         const querySnapshot = await getDocs(collection(db, 'jobs'));


    //         const jobs = [];
    //         querySnapshot.forEach(doc => {
    //             jobs.push({ id: doc.id, ...doc.data() });
    //         });

    //         dispatch(getJobsSuccess(jobs));
    //         dispatch(siActions.showSpinner({ open2: false }));
    //     } catch (error) {
    //         dispatch(hasError(error.message));
    //         dispatch(siActions.showSpinner({ open2: false }));
    //     }
    // }
    // else {
    try {
        const querySnapshot = await getDocs(collection(db, 'posts'));


        const posts = [];
        querySnapshot.forEach(doc => {
            posts.push({ id: doc.id, ...doc.data() });
        });

        dispatch(getPostsSuccess(posts));
        dispatch(siActions.showSpinner({ open2: false }));
    } catch (error) {
        dispatch(hasError(error.message));
        dispatch(siActions.showSpinner({ open2: false }));
    }
    // }
};

export const getAppliedJobs = (mode) => async dispatch => {
    dispatch(startLoading());
    dispatch(siActions.showSpinner({ open2: true }));
    try {
        const querySnapshot = await getDocs(collection(db, 'appliedforjobs'));


        const jobs = [];
        querySnapshot.forEach(doc => {
            jobs.push({ id: doc.id, ...doc.data() });
        });
        console.log(jobs);

        dispatch(getJobsSuccess(jobs));
        dispatch(siActions.showSpinner({ open2: false }));
    } catch (error) {
        dispatch(hasError(error.message));
        dispatch(siActions.showSpinner({ open2: false }));
    }

};
export const getPostByUrl = url => async dispatch => {
    dispatch(startLoading());
    try {
        const post = [];

        const q = query(collection(db, 'posts'), where('blogurl', '==', url));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            post.push({ id: doc.id, ...doc.data() });

        });
        dispatch(getPostByUrlSuccess(post));
    } catch (error) {
        dispatch(hasError(error.message));
    }
};
export const getPostById = postId => async dispatch => {
    dispatch(startLoading());

    try {
        const postDoc = await getDoc(doc(db, 'posts', postId));
        if (postDoc.exists()) {
            const postData = postDoc.data();
            dispatch(getPostByIdSuccess({ id: postDoc.id, ...postData }));
        } else {
            throw new Error(`Post with ID ${postId} does not exist`);
        }
    } catch (error) {
        dispatch(hasError(error.message));
    }
}
// export const updatePost = (postId, updatedPostData, navigate, mode) => async dispatch => {
//     dispatch(startLoading());

//     try {
//         const postRef = doc(db, 'posts', postId);
//         // const postSnapshot = await getDoc(postRef);
//         // console.log(postSnapshot,"postSnapshot");
//         // if (postSnapshot.exists()) {
//             console.log("wee");
//         await updateDoc(postRef, updatedPostData);
//         const updatedPost = { id: postId, ...updatedPostData };
//         dispatch(updatePostSuccess(updatedPost));
//         navigate(`/${mode}/${updatedPostData.blogurl}`);
//         console.log(await updateDoc(postRef, updatedPostData));
//         // } else {
//         //     throw new Error(`Post with id ${postId} does not exist`);
//         // }
//     } catch (error) {
//         dispatch(hasError(error.message));
//     }
// };


export const updatePost = (postId, updatedPostData, navigate, mode) => async (dispatch) => {
    try {
        const postRef = doc(db, "posts", postId);
        await updateDoc(postRef, updatedPostData);
        dispatch(updatePostSuccess(updatedPostData));
        navigate(`/${mode}/${updatedPostData.blogurl}`);

        //         navigate(`/${mode}/${updatedPostData.blogurl}`);
        // // Perform any necessary actions after updating the post
        // // For example, you can navigate to a different page
        // navigate(); // Call the navigate function here if needed

        // // Dispatch any necessary actions after updating the post
        // // For example, you can dispatch an action to update the state
        // dispatch({
        //   type: "POST_UPDATED",
        //   payload: updatedPostData,
        // });
    } catch (err) {
        console.log(err, "err");
    }
};

export const getPostsByCategory = category => async dispatch => {
    dispatch(startLoading());
    try {
        const posts = [];

        const q = query(collection(db, 'posts'), where('category', '==', category));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            posts.push({ id: doc.id, ...doc.data() });
        });
        dispatch(getPostsByCategorySuccess(posts));
    } catch (error) {
        dispatch(hasError(error.message));
    }
};

export const createCate = (cate) => async dispatch => {
    dispatch(startLoading());
    dispatch(
        uiActions.showNotification({
            open: true,
            message: "sending Request",
            type: "warning",
        })
    );
    try {
        // Check if blogurl already exists in Firestore
        const querySnapshot = await getDocs(query(collection(db, "cate"), where("cate", "==", cate)));
        if (!querySnapshot.empty) {
            dispatch(
                uiActions.showNotification({
                    open: true,
                    message: "Category already exists.",
                    type: "error",
                })
            );
            throw new Error("Category already exists.");
        }
        const newPostRef = await addDoc(collection(db, "cate"), {
            cate,
            createdAt: Date.now(),
        });
        const newPost = {
            id: newPostRef.id,
            cate,
            createdAt: Date.now()
        };
        dispatch(createCateSuccess(newPost));

        dispatch(
            uiActions.showNotification({
                open: true,
                message: "Category Added successfully ",
                type: "success",
            })
        );
    } catch (error) {
        dispatch(hasError(error.message));
        dispatch(
            uiActions.showNotification({
                open: true,
                message: error.message,
                type: "error",
            })
        );
    }
};
export const getCate = () => async dispatch => {
    dispatch(startLoading());
    dispatch(siActions.showSpinner({ open2: true }));
    try {
        const querySnapshot = await getDocs(collection(db, 'cate'));
        const cate = [];
        querySnapshot.forEach(doc => {
            cate.push({ id: doc.id, ...doc.data() });
        });
        dispatch(getCateSuccess(cate));
        dispatch(siActions.showSpinner({ open2: false }));
    } catch (error) {
        dispatch(hasError(error.message));
        dispatch(siActions.showSpinner({ open2: false }));
    }
};


export const deletePost = postId => async dispatch => {
    dispatch(
        uiActions.showNotification({
            open: true,
            message: "Deleting...",
            type: "warning",
        })
    );
    try {
        const postDocRef = doc(db, "posts", postId);
        await deleteDoc(postDocRef);
        dispatch(deletePostSuccess(postId));

        // Dispatch getPosts action to refresh the content of the table
        dispatch(getPosts());

        dispatch(
            uiActions.showNotification({
                open: true,
                message: "Delete successfully ",
                type: "success",
            })
        );
    } catch (error) {
        dispatch(
            uiActions.showNotification({
                open: true,
                message: error.message,
                type: "error in deleting",
            })
        );
    }
};

export const deleteJob = postId => async dispatch => {
    dispatch(
        uiActions.showNotification({
            open: true,
            message: "Deleting...",
            type: "warning",
        })
    );
    try {
        const postDocRef = doc(db, "appliedforjobs", postId);
        await deleteDoc(postDocRef);
        dispatch(deleteJobSuccess(postId));


        dispatch(
            uiActions.showNotification({
                open: true,
                message: "Delete successfully ",
                type: "success",
            })
        );
        dispatch(getAppliedJobs());
    } catch (error) {
        dispatch(
            uiActions.showNotification({
                open: true,
                message: error.message,
                type: "error in deleting",
            })
        );
    }
};
export const searchBlogs = (searchTerm) => async (dispatch) => {
    dispatch(siActions.showSpinner({ open2: true }));
    try {
        const q = query(collection(db, 'posts'), where('title', '>=', searchTerm));
        const querySnapshot = await getDocs(q);
        const posts = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        dispatch(searchBlogsSuccess(posts));
        dispatch(siActions.showSpinner({ open2: false }));
    } catch (error) {
        dispatch(hasError(error.message));
        dispatch(siActions.showSpinner({ open2: false }));
    }
};


export const uploadFile = (file) => {
    return async (dispatch) => {
        try {
            const storageRef = storage.ref();
            const fileRef = storageRef.child(file.name);
            await fileRef.put(file);
            const downloadURL = await fileRef.getDownloadURL();
            console.log(downloadURL);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };
};

export const { startLoading, hasError, createPostSuccess, createJobsSuccess, createCateSuccess } = postsSlice.actions;
export default postsSlice.reducer;