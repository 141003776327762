import React, { useState } from 'react';
import SignUp from './SignUp';

const SuperAdmin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showSignUp, setShowSignUp] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();

    const adminUsername = 'aquagames';
    const adminPassword = 'aquagames@@007';

    if (username === adminUsername && password === adminPassword) {
      setShowSignUp(true);
    } else {
      setErrorMessage('Invalid credentials');
    }
  };

  if (showSignUp) {
    return <SignUp />;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <form onSubmit={handleLogin} className="mb-4">
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="border border-gray-400 rounded px-4 py-2 mb-2"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="border border-gray-400 rounded px-4 py-2 mb-2"
        />
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Login
        </button>
      </form>
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
    </div>
  );
};

export default SuperAdmin;
