import React from 'react'
import DashHeader from '../components/DashHeader'
import Sidebar from '../components/Sidebar'
import { useDispatch, useSelector } from 'react-redux'
import { createCate, deleteCate, getCate, updateCate } from '../../features/cateSlice'
import { useCallback } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'


export const AddCategory = () => {
    const cate = useSelector(state => state.cate.cate);
    const dispatch = useDispatch();
    const [updateMode, setUpdateMode] = useState(false);
    const [mode, setMode] = useState('blog');
    const [upID, setUpID] = useState(null);
    const [newCate, setNewCate] = useState("");
    useEffect(() => {
        dispatch(getCate());
    }, [dispatch]);
    const handleSubmit = e => {
        e.preventDefault();
        try {
            let cate = e.target.category.value;
            dispatch(createCate(cate, mode));
            e.target.category.value = ""

        } catch (err) {

        }
    };


    const handleSetUpdate = useCallback(
        (did, t, nc) => {
            return async () => {
                setUpID(did);
                setUpdateMode(true);
                setNewCate(nc)
            };
        },
        []
    );


    const handleUpdate = (e) => {
        e.preventDefault();
        try {
            let cate = e.target.category.value;
            dispatch(updateCate(upID, cate, mode));
            setUpdateMode(false);
            dispatch(getCate());

        } catch (err) {

        }

    }
    const handleDelete = useCallback(
        (did) => async () => {
            dispatch(deleteCate(did));
        },
        [dispatch]
    );
    const handleModeChange = (e) => {
        setMode(e.target.value);
    };
    return (
        <div className="">
            <DashHeader />
            <Sidebar />
            <div className="p-4  sm:ml-64 ">
                <div className="p-4 mt-10 ">

                    {updateMode ?
                        (<form className='p-1 md:px-20 pt-4' onSubmit={handleUpdate}>
                            <div className="mb-6">
                                <label htmlFor="mode" className="block mb-2 text-sm font-medium text-gray-900 ">Mode</label>
                                <select id="mode" value={mode} onChange={handleModeChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option value="blog">Blog Mode</option>
                                    <option value="career">Career Mode</option>
                                </select>
                            </div>
                            <div className="mb-6">
                                <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 ">Update Category</label>
                                <input type="text" required name="category" id="category" defaultValue={newCate} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                            </div>
                            <div className="mb-6">
                                <button type="submit" className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2   dark:focus:ring-gray-700 ">Update</button>

                                <button onClick={() => setUpdateMode(false)} className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2   dark:focus:ring-gray-700 ">Cancel</button>
                            </div>
                        </form>) : (
                            <form className='p-1 md:px-20 pt-4' onSubmit={handleSubmit}>
                                <div className="mb-6">
                                    <label htmlFor="mode" className="block mb-2 text-sm font-medium text-gray-900 ">Mode</label>
                                    <select id="mode" value={mode} onChange={handleModeChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                        <option value="blog">Blog Mode</option>
                                        <option value="career">Career Mode</option>
                                    </select>
                                </div>
                                <div className="mb-6">
                                    <label htmlFor="category" className="block mb-2 text-sm font-medium text-red-500">Add Category in this format (Aqua  or Aqua-Games)</label>
                                </div>
                                <div className="mb-6">
                                    <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 ">Add Category</label>
                                    <input type="text" required name="category" id="category" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                </div>
                                <div className="mb-6">
                                    <button type="submit" className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2   dark:focus:ring-gray-700 ">Add</button>
                                </div></form>

                        )}

                </div>
                <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8 pt-0">
                    <div className="overflow-x-auto">
                        <div className="align-middle inline-block min-w-full shadow-lg rounded-lg overflow-hidden">
                            <table className="w-full text-sm text-left  ">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
                                    <tr>
                                        <th className="px-6 py-3 border-b border-gray-600 text-left text-xs leading-4 font-medium  uppercase tracking-wider">Category Name</th>
                                        <th className="px-6 py-3 border-b border-gray-600 text-left text-xs leading-4 font-medium  uppercase tracking-wider">Update</th>
                                        <th className="px-6 py-3 border-b border-gray-600 text-left text-xs leading-4 font-medium  uppercase tracking-wider">Delete</th>
                                    </tr>
                                </thead>
                                <tbody >

                                    {cate && cate.map(i => (
                                        i.mode === mode && (
                                            <tr className="text-gray-900 bg-white border-b   text-white" key={i.id}>
                                                <td className="px-6 py-4 ">
                                                    <div className="flex items-center">
                                                        <div className="ml-4">
                                                            <div className="text-sm leading-5 font-medium text-gray-900  ">{i.cate}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 ">
                                                    <button onClick={handleSetUpdate(i.id, true, i.cate)} ><span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-400 text-gray-800">Update</span></button>
                                                </td>
                                                <td className="px-6 py-4 ">
                                                    <button className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-400 text-gray-800" onClick={handleDelete(i.id)}>Delete</button>
                                                </td>
                                            </tr>)

                                    ))}



                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div >
    )
}
