import React from 'react';

export const SliderPagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
    const totalPages = Math.ceil(totalPosts / postsPerPage);

    if (totalPages <= 1) {
        return null;
    }

    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;

    const handleClick = (page) => {
        if (page === 'dots1' || page === 'dots2') {
            return null;
        }
        paginate(page);
    };

    const renderPageLink = (page, label) => {
        return (
            <div
                onClick={() => handleClick(page)}
                className={`mx-1 flex h-9 w-9 items-center text-${currentPage === page ? 'white' : 'red'
                    } bg-${currentPage === page ? 'red' : 'transparent'
                    } justify-center rounded-full border border-blue-gray-100 p-0 text-sm ${currentPage === page
                        ? 'bg-slate-950'
                        : 'text-blue-gray-500 hover:bg-light-300 transition duration-150 ease-in-out'
                    }`}
            >
                {label}
            </div>
        );
    };

    const paginationNumbers = () => {
        const links = [];

        // Show first page link
        links.push(renderPageLink(1, 1));

        // Show page numbers
        let min = Math.max(currentPage - 1, 2);
        let max = Math.min(currentPage + 2, totalPages - 1);
        if (max - min < 3) {
            if (currentPage < 3) {
                max = Math.min(currentPage + 3, totalPages - 1);
            } else {
                min = Math.max(currentPage - 3, 2);
            }
        }
        if (min > 2) {
            links.push(renderPageLink('dots1', '...'));
        }
        for (let i = min; i <= max; i++) {
            links.push(renderPageLink(i, i));
        }
        if (max < totalPages - 1) {
            links.push(renderPageLink('dots2', '...'));
        }

        // Show last page link
        if (totalPages > 1) {
            links.push(renderPageLink(totalPages, totalPages));
        }

        return links;
    };

    return (
        <nav>
            <ul className="flex">
                {!isFirstPage && (
                    <li>
                        <div
                            className="mx-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-100 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300"
                            aria-label="Previous"
                            onClick={() => handleClick(currentPage - 1)}
                        >
                            <span className="material-icons text-sm">
                                <svg
                                    className="h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                </svg>
                            </span>
                        </div>
                    </li>
                )}
                {paginationNumbers().map((link, index) => (
                    <li key={index}>{link}</li>
                ))}
                {!isLastPage && (
                    <li>
                        <div
                            className="mx-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-100 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300"
                            aria-label="Next"
                            onClick={() => handleClick(currentPage + 1)}
                        >
                            <span className="material-icons text-sm">
                                <svg
                                    className="h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                </svg>
                            </span>
                        </div>
                    </li>
                )}
            </ul>
        </nav>
    );
};
