import { createSlice } from "@reduxjs/toolkit";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { uiActions } from "./uiSlice";
import { siActions } from "./SpinnerSlice";

const initialState = {
    posts: [],
    isLoading: false,
    error: null,
    post: null,
    isSubscribing: false,
    subscribeError: null,
    subscribe: []
};
export const getSubscribedEmailsSuccess = subscribe => ({
    type: 'subscribe/getSubscribedEmailsSuccess',
    payload: subscribe
});
export const subscribeEmail = email => async dispatch => {
    dispatch(subscribeStart());
    dispatch(
        uiActions.showNotification({
            open: true,
            message: "Subscribing...",
            type: "warning",
        })
    );
    try {
        // call Firebase auth API to create a user with the email and a random password
        // await createUserWithEmailAndPassword(auth, email, Math.random().toString(36).substring(2, 15));
        const emailRef = doc(db, 'subscribed_emails', email);
        await setDoc(emailRef, { subscribed_at: new Date() });
        dispatch(subscribeSuccess());
        dispatch(
            uiActions.showNotification({
                open: true,
                message: `Thank you for subscribing, ${email}!`,
                type: "success",
            })
        );

        // sendNewsletter
    } catch (error) {
        dispatch(
            uiActions.showNotification({
                open: true,
                message: "Error in Subscribing",
                type: "error",
            })
        );
    }
};

export const getEmail = () => async dispatch => {
    dispatch(siActions.showSpinner({ open2: true }));
    try {
        const emailsRef = collection(db, 'subscribed_emails');
        const snapshot = await getDocs(emailsRef);
        const subscribe = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        dispatch(getSubscribedEmailsSuccess(subscribe));
        dispatch(siActions.showSpinner({ open2: false }));
    } catch (error) {
        dispatch(siActions.showSpinner({ open2: false }));
    }

};

const subscribeSlice = createSlice({
    name: 'subscribe',
    initialState,
    reducers: {
        subscribeStart: state => {
            state.isSubscribing = true;
            state.subscribeError = null;
        },
        subscribeSuccess: state => {
            state.isSubscribing = false;
        },
        subscribeError: (state, action) => {
            state.isSubscribing = false;
            state.subscribeError = action.payload;
        },
        getSubscribedEmailsSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.subscribe = action.payload;
        },
    },
});

export const { subscribeStart, subscribeSuccess, subscribeError } = subscribeSlice.actions;

export default subscribeSlice.reducer;
