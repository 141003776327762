import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getPosts, getPostsByCategory } from '../features/postsSlice';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import { PaginationBlogs } from '../partials/PaginationBlogs';
import classNames from 'classnames';
export const Career = () => {

    const cate = useSelector(state => state.cate.cate);

    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(5);
    const [cateData, setCateData] = useState("");

    useEffect(() => {
        cateData ?
            dispatch(getPostsByCategory(cateData))
            : dispatch(getPosts());
    }, [dispatch, cateData]);


    const posts = useSelector((state) => state.posts.posts);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const filterPost = posts.filter((post) => post.mode === 'career');
    const currentPosts = filterPost.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const toggleDropdown = () => setIsOpen(!isOpen)
    const [isOpen, setIsOpen] = useState(false);
    const [isGridActive, setGridActive] = useState(false);

    const handleListClick = (event) => {
        event.preventDefault();
        setGridActive(false);
    };

    const handleGridClick = (event) => {
        event.preventDefault();
        setGridActive(true);
    };
    return (
        <div>
            <Header />
            <div>
                {currentPosts ?
                    <aside aria-label="Related articles" className="pt-4 py-8 lg:py-24 bg-gray-50 ">
                        <div className="px-4 mx-auto max-w-screen-xl ">
                            <div className="relative">
                                <button id="dropdownDefaultButton"
                                    onClick={toggleDropdown} className="btn btn_black btn_anim catebtn">Category <span></span><span></span><span></span><span></span>                                    <svg
                                        className={`w-4 h-4 ml-2 ${isOpen ? 'transform rotate-180' : ''}`}
                                        aria-hidden="true"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                    </svg></button>
                                <div
                                    id="dropdown"
                                    className={`absolute w-50 flex z-10 ${isOpen ? '' : 'hidden'} bg-white divide-y divide-gray-100 rounded-lg  shadow  `}
                                >
                                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                                        {cate.map(c => (
                                            c.mode === "career" &&
                                            (
                                                <li key={c.id}>

                                                    <button onClick={() => setCateData(c.cate)} className="block px-4 py-2 hover:bg-gray-100 w-full">{c.cate}</button></li>

                                            )
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="bk_list_position">
                                <div className="head_position d-flex align-items-center aos-init aos-animate" data-aos="fade-up" data-aos-delay="150">
                                    <h2>Latest Openings</h2>
                                    <div className="btns_styls ml-auto">
                                        <button id="grid" className={classNames('btn', 'btn_grid', { 'active': isGridActive })} onClick={handleGridClick}></button>
                                        <button id="list" className={classNames('btn', 'btn_list', { 'active': !isGridActive })} onClick={handleListClick}></button>
                                    </div>
                                </div>
                                <div id="positions" className={classNames('row', 'view-group')}>
                                    <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-3">
                                        {currentPosts.map(i => (
                                            currentPosts ? (

                                                <Link to={`/career/${i.blogurl}`} key={i.id} className={classNames('item', 'col-md-6', 'aos-init', 'aos-animate', { 'grid-group-item': isGridActive })} data-aos="fade-up" data-aos-delay="200">
                                                    <div className="position_box clearfix" >
                                                        <div className="position_pic img-hover"><img src={i.imageUrl} alt={i.altname} /></div>
                                                        <div className="position_cption">
                                                            <h2><span>{i.title}</span></h2>
                                                            <h3>{i.sType}</h3>
                                                            <p >View Details</p>
                                                            <div className="label_pos">Full-Time</div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            ) : (
                                                <h2 className='text-sm text-gray-700'>Currently No Openings</h2>
                                            )
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <PaginationBlogs
                                postsPerPage={postsPerPage}
                                totalPosts={filterPost.length}  
                                paginate={paginate}
                                currentPage={currentPage}
                            />

                        </div>
                    </aside > : null}
            </div >
            <Footer />
        </div >

    )
}
