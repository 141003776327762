import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions, httpsCallable } from 'firebase/functions'
const firebaseConfig = {
  apiKey: "AIzaSyDpjTzPd7EmPzQqQf8lBt3b77bk-MJlg-4",
  authDomain: "aquagames2023.firebaseapp.com",
  projectId: "aquagames2023",
  storageBucket: "aquagames2023.appspot.com",
  messagingSenderId: "951683777497",
  appId: "1:951683777497:web:7712ed34464ad4ab844fc8"
};

const app=initializeApp(firebaseConfig);

//init services
const auth = getAuth();
const db = getFirestore(app);
const storage = getStorage(app)
const functions = getFunctions(app);
const http=httpsCallable;
export {
  auth,
  db,
  storage,
  http,
  functions,
  createUserWithEmailAndPassword,
  updateProfile,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
};
