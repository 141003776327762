import React, { useState } from 'react';
import { signUp } from '../features/authSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from '../firebase';
import { useDropzone } from 'react-dropzone';
import { siActions } from '../features/SpinnerSlice';
import { useEffect } from 'react';
import logo from "../images/logo.svg"


function SignUp() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');

  const [profileImage, setProfileImage] = useState(null); // Use null instead of an empty string
  const [jobTitle, setJobTitle] = useState('');
  const type = "user";
  const [file, setFile] = useState(null);
  const { loading, error } = useSelector((state) => state.auth);
  const [disable, setDisable] = useState(true);
  let navigate = useNavigate();
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    accept: 'image/*',
    maxFiles: 1, // Only allow one file to be uploaded
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
  });

  const handleReset = () => {
    setFile(null);
  };

  useEffect(() => {
    const uploadFile = () => {

      try {
        dispatch(
          siActions.showSpinner({
            open2: true,
          })
        );
        const storageRef = ref(storage, file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // setProgress(
            //     (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            // );
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if (progress === 100) {
              dispatch(
                siActions.showSpinner({
                  open2: false,
                })
              );
            }

          },

          (error) => {

          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setDisable(false)
              setProfileImage(downloadURL);
            });
          }
        );


      } catch (err) {
        dispatch(
          siActions.showSpinner({
            open2: false,
          })
        );
      }
    };
    file && uploadFile();
  }, [file, dispatch])
  const handleSignUp = async (event) => {
    event.preventDefault();
    dispatch(signUp({ email, password, profileImage, userName, jobTitle, type, navigate }));
  };







  return (
    <section className="bg-gray-50 h-full mt-10  ">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a href="/comingsoon" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 ">
          <img
            src={logo}
            className="h-full mr-3 sm:h-9"
            alt="Aqua GamesLogo"
            style={{ height: "50px", width: "50px" }}
          />
          Aqua Games
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0  ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
              Create Account
            </h1>
            <form onSubmit={handleSignUp} className="space-y-4 md:space-y-6">
              <div>
                <label htmlFor="profileImage" className="block mb-2 text-sm font-medium text-gray-900 ">Profile Image</label>
                <input {...getInputProps()} />
                {file ? (
                  <div>
                    <div className="relative">
                      <img
                        src={URL.createObjectURL(file)}
                        alt="Uploaded file"
                        className="h-full object-contain uploadImageIcon"
                      />
                      <button
                        onClick={handleReset}
                        className="absolute top-0 right-0 mt-2 mr-2 bg-white rounded-full p-2 hover:bg-gray-200 focus:outline-none"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                      </button>
                    </div>

                  </div>
                ) : (
                  <div className="flex items-center justify-center w-full"  {...getRootProps()}>
                    <label htmlFor="dropzone-file" className={`flex flex-col items-center text-center justify-center w-full h-auto border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800  hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 ${isDragActive
                      ? 'border-green-500 bg-green-100'
                      : 'border-gray-400 bg-gray-100'
                      } ${isDragReject ? 'border-red-500 bg-red-100' : ''}`}>
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                        <p className="mb-2 text-sm text-center  "><span className="font-semibold">Click to upload</span> {isDragActive ? 'Drop the image here' : 'Drag and drop an image here, or click to select one'}</p>
                        <p className="text-xs  ">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                      </div>
                    </label>
                  </div>

                )}
              </div>
              <div>
                <label htmlFor="userName" className="block mb-2 text-sm font-medium text-gray-900 ">Username</label>
                <input disabled={disable} type="text" name="userName" id="userName" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Username..." required="" value={userName}
                  onChange={(e) => setUserName(e.target.value)} />
              </div>
              <div>
                <label htmlFor="jobTitle" className="block mb-2 text-sm font-medium text-gray-900 ">Job Title</label>
                <input disabled={disable} type="text" name="jobTitle" id="jobTitle" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Job Title..." required="" value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)} />
              </div>
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Your email</label>
                <input disabled={disable} type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Email..." required="" value={email}
                  onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 ">Password</label>
                <input disabled={disable} type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" value={password}
                  onChange={(e) => setPassword(e.target.value)} />

              </div>
              <div className="w-full px-3">
                <button type="submit" className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none atbtn focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800" disabled={loading}>Sign Up</button>
                {loading ? 'Account is creating...' : null}
                {error && <p>{error.message}</p>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );

}
export default SignUp;