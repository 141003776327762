import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getPosts } from '../features/postsSlice';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import dateFormat from 'dateformat';
import { PaginationBlogs } from '../partials/PaginationBlogs';
import playStoreIcon from '../images/plat-store.png'
import AppStoreIcon from '../images/app-store.png'
export const AllGames = () => {;

    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(12);

    useEffect(() => {
        dispatch(getPosts());
    }, [dispatch]);

    const posts = useSelector((state) => state.posts.posts);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const filterPost = posts.filter((post) => post.category === 'Games' || post.category === 'Featured');
    const currentPosts = filterPost.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);


    return (
        <div>
            <Header />
            <div>
                {currentPosts ?
                    <aside aria-label="Related articles" className="pt-4 py-8 lg:py-24 bg-gray-50 ">
                        <div className="px-4 mx-auto max-w-screen-xl ">
                            <h2 className="mt-8 mb-8 text-2xl font-bold text-gray-900 ">All Games</h2>
                            <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-4">
                                {currentPosts.map(i => (
                                    currentPosts ?
                                        (
                                            <Link to={`/blog/${i.blogurl}`} key={i.id} className="">
                                                <div className="card">
                                                    <div className="card__header">
                                                        <img src={i.imageUrl} alt={i.altname} className="card__image" width="600" />
                                                    </div>
                                                    <div className="card__body">
                                                        <h4>{i.title}</h4>
                                                        <p className=" overflow-hidden  mt-auto flex-grow line-clamp-3" dangerouslySetInnerHTML={{ __html: i.content }}></p>
                                                    </div>
                                                    <div className='pt-2  w-full text-center flex items-center justify-start px-4'>
                                                            <div className="flex items-center justify-center">
                                                                {
                                                                    i.playstoreurl ?
                                                                        <a href={i.playstoreurl} rel="noreferrer" target='_blank'>
                                                                            <img src={playStoreIcon} alt="play store icon" width={20} height={20} />
                                                                        </a>
                                                                        : null
                                                                }
                                                                {
                                                                    i.appstoreurl ?
                                                                        <a href={i.appstoreurl} rel="noreferrer" target='_blank'>
                                                                            <img src={AppStoreIcon} className='ml-3' alt="app store icon" width={20} height={20} />
                                                                        </a>
                                                                        : null
                                                                }
                                                            </div>
                                                            {
                                                                i.publishwith ?
                                                                    <span className='text-xs ml-2'>Published with {i.publishwith}</span>
                                                                    : null
                                                            }
                                                        </div>
                                                    <div className="card__footer">
                                                        <div className="user">
                                                            <div className="user__info">
                                                                <small> {dateFormat(
                                                                    i.createdAt,
                                                                    "mmmm dS, yyyy"
                                                                )}</small>
                                                            </div>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </Link>
                                        ) : (
                                            null
                                        )
                                ))}
                            </div>
                            <PaginationBlogs
                                postsPerPage={postsPerPage}
                                totalPosts={filterPost.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />

                        </div>
                    </aside > : null}
            </div >
            <Footer />
        </div >
    )
}
