
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subscribeEmail } from '../features/subscribeSlice';
function Newsletter() {

  const dispatch = useDispatch();
  const { isSubscribing, error } = useSelector(state => state.subscribe);
  const [email, setEmail] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(subscribeEmail(email));
  };



  return (
    <section className="bk_section_newsletter">
      <div className="container mx-auto">
        <div className="flex flex-row items-center flex-wrap justify-center lg:justify-between ">
          <div className="w-100 lg:w-6/12">
            <div className="newsletter_title">
              <h2 data-aos="fade-up" data-aos-delay="100" className="aos-init aos-animate">OUR NEWSLETTER</h2>
            </div>
          </div>
          <div className="w-auto">
            {error && <p>{error.message}</p>}
            <form className="newsletter_form aos-init aos-animate" data-aos="fade-up" data-aos-delay="150" id="newsletter" onSubmit={handleSubmit}>
              <input type="email" name="email" className="form-control" placeholder="Enter email address" value={email} onChange={e => setEmail(e.target.value)} />
              <button type="submit" className="btn btn_news btn_anim" disabled={isSubscribing}>
                {isSubscribing ? 'Subscribing...' : 'Subscribe'}
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>

  );
}

export default Newsletter;
