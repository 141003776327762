import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getPostByUrl } from '../features/postsSlice';
import Header from '../partials/Header';
import dateFormat from 'dateformat';
import Footer from '../partials/Footer';
import playStoreIcon from '../images/plat-store.png'
import AppStoreIcon from '../images/app-store.png'
export const SingleBlog = () => {
    const dispatch = useDispatch();
    const { url } = useParams();
    const post = useSelector(state => state.posts.post);
    const isLoading = useSelector(state => state.posts.isLoading);
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        dispatch(getPostByUrl(url));
    }, [dispatch, url]);

    if (isLoading || !post) {
        return <div className=''>Loading...</div>;
    }

    return (
        <div className="">
            <Header />
            <div className="">
                {Array.isArray(post) && post.map(i => (
                    <div key={i.id}>
                        <Helmet>
                            <title>{i.metatitle}</title>
                            <meta name="description" content={i.metadescription} />
                            <meta name="keywords" content={i.metakeyword} />
                        </Helmet>
                        <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white ">
                            <div className="flex justify-between px-4 mx-auto max-w-screen-xl ">
                                <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
                                    <header className="mb-4 lg:mb-6 not-format">
                                        <address className="flex items-center mb-6 not-italic">
                                            <div className="inline-flex items-center mr-3 text-sm text-gray-900 ">
                                                <img className="mr-4 w-16 h-16 rounded-full" src={i.profileImage} alt="user img" />
                                                <div>
                                                    <p rel="author" className="text-xl font-bold text-gray-900 ">{i.userName}</p>
                                                    <p className="text-base font-light  ">{i.jobTitle}</p>
                                                    <p className="text-base font-light  "><time dateTime="2022-02-08" title="February 8th, 2022">
                                                        {dateFormat(
                                                            i.createdAt,
                                                            "mmmm dS, yyyy"
                                                        )}</time></p>
                                                </div>
                                            </div>
                                            {user && i.userid === user.userData.uid ? (
                                                <Link to={`/update/${i.id}/blog`}><span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-400 text-gray-800">Update</span></Link>
                                            ) : null}

                                        </address>
                                        <h1 className="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl ">{i.title}</h1>

                                    </header>


                                    <div className="max-w-3xl mx-auto">
                                        <img src={i.imageUrl} alt={i.altname} className="w-full object-cover object-center rounded-lg shadow-md" />
                                    </div>
                                    <div className='mt-4 w-full text-center flex items-center justify-start'>
                                        <div className="flex items-center justify-center">
                                            {
                                                i.playstoreurl ?
                                                    <a href={i.playstoreurl} target='_blank' rel='noreferrer'>
                                                        <img src={playStoreIcon} alt="play store icon" width={30} height={30} />
                                                    </a>
                                                    : null
                                            }
                                            {
                                                i.appstoreurl ?
                                                    <a href={i.appstoreurl} target='_blank'rel='noreferrer'>
                                                        <img src={AppStoreIcon} className='ml-5' alt="app store icon" width={30} height={30} />
                                                    </a>
                                                    : null
                                            }
                                        </div>
                                        {
                                            i.publishwith ?
                                                <span className='text-sm ml-3'>Published with {i.publishwith}</span>
                                                : null
                                        }
                                    </div>
                                    <div className='mt-4 ' dangerouslySetInnerHTML={{ __html: i.content }} />


                                </article>
                            </div>
                        </main>




                    </div>

                ))}
            </div>
            <Footer />
        </div>
    );


}
