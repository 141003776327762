import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getpartner } from '../features/partnerSlice';
import Carousel from "react-multi-carousel";
export const Partners = () => {
  const partner = useSelector(state => state.partner.partner);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getpartner());
  }, [dispatch]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      partialVisibilityGutter: 40 // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    }
  }

  return (
    <div className='container mx-auto'>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={1000}
        transitionDuration={500}
        containerClass="carousel-container"
      >
        {partner.map(p => (
          <div className="partnercard" key={p.id}>
            <img src={p.imageUrl} alt={p.id}  />
          </div>
        ))
        }
      </Carousel >
    </div >
  );
};
