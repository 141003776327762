import { createSlice } from '@reduxjs/toolkit';
import { auth, db } from '../firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut } from 'firebase/auth';
import { uiActions } from './uiSlice';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';

const initialState = {
    user: JSON.parse(localStorage.getItem("userData")) || null,
    loading: false,
    error: null,
    profileImage: null,
    userName: null,
    title: null,
    type: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setProfileImage: (state, action) => {
            state.profileImage = action.payload;
        },
        setUserName: (state, action) => {
            state.userName = action.payload;
        },
        setJobTitle: (state, action) => {
            state.jobTitle = action.payload;
        },
        setType: (state, action) => {
            state.type = action.payload;
        },

    },
});

export const signIn = ({ email, password, rememberMe, navigate }) => async (dispatch) => {
    dispatch(setLoading(true));

    try {
        const { user } = await signInWithEmailAndPassword(auth, email, password);
        const q = query(collection(db, 'users'), where('uid', '==', user.uid));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.docs.length === 0) {
            throw new Error("User profile not found");
        }
        const userData = querySnapshot.docs[0].data();

        const userDataToStore = {
            ...userData,
            email: user.email,
            rememberMe: rememberMe
        };

        if (!rememberMe) {
            const expirationTime = new Date().getTime() + 1 * 60 * 1000; // 1 minute from now
            localStorage.setItem("userData", JSON.stringify({ userData: userDataToStore, expirationTime }));

            // Set a timeout to remove the userData from localStorage after 1 minute
            setTimeout(() => {
                localStorage.removeItem("userData");
            }, 2 * 60 * 60 * 1000); // 2 hours from now

        } else {
            localStorage.setItem("userData", JSON.stringify({ userData: userDataToStore }));
        }

        dispatch(setUser(userData));
        dispatch(setProfileImage(userData.profileImage));
        dispatch(setUserName(userData.userName));
        dispatch(setJobTitle(userData.jobTitle));
        dispatch(setType(userData.type));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setError(error));
        dispatch(setLoading(false));
    }
};


export const getUserDataFromStorage = () => async (dispatch) => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (storedData) {
        const { userData, expirationTime } = storedData;
        if (!expirationTime || new Date().getTime() <= expirationTime) {
            return userData;
        }
        localStorage.removeItem("userData");
        dispatch(
            uiActions.showNotification({
                open: true,
                message: "your Login Session is expire ,Please Login Again",
                type: "error",
            })
        );

    }
    return null;
};

export const signUp = ({ email, password, profileImage, userName, jobTitle, type, navigate }) => async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(
        uiActions.showNotification({
            open: true,
            message: "Creating Account...",
            type: "warning",
        })
    );

    try {
        if (!email || !password) {
            dispatch(setError('Email and password are required.'));
            return;
        }
        const { user } = await createUserWithEmailAndPassword(auth, email, password);
        const querySnapshot = await getDocs(query(collection(db, "users"), where("email", "==", email)));
        if (!querySnapshot.empty) {
            dispatch(
                uiActions.showNotification({
                    open: true,
                    message: "Email already exists.",
                    type: "error",
                })
            );
            throw new Error("Email already exists.");
        }
        // If blogurl doesn't exist, add the new post to Firestore
        let uid = user.uid;
        const newPostRef = await addDoc(collection(db, "users"), {
            email, profileImage, userName, jobTitle, type, uid,
            createdAt: Date.now(),
        });
        const newPost = {
            id: newPostRef.id, email, profileImage, userName, jobTitle, type,
            createdAt: Date.now()
        };
        // // Update local state
        dispatch(setProfileImage(profileImage));
        dispatch(setUserName(userName));
        dispatch(setJobTitle(jobTitle));
        dispatch(setType(type));
        dispatch(setUser(newPost));
        dispatch(
            uiActions.showNotification({
                open: true,
                message: "Account created successfully ",
                type: "success",
            })
        );
        navigate("/dashboard")
        dispatch(setLoading(false));


    } catch (error) {
        dispatch(setError(error));
        dispatch(setLoading(false));
        dispatch(
            uiActions.showNotification({
                open: true,
                message: error.message,
                type: "error in creating account",
            })
        );
    }
};


export const signOutUser = (navigate) => async (dispatch) => {
    dispatch(setLoading(true));

    try {
        await signOut(auth);
        dispatch(setUser(null));
        localStorage.setItem("userData", null);
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setError(error));
        dispatch(setLoading(false));
    }
};

export const { setUser, setLoading, setError, setProfileImage, setUserName, setJobTitle, setType } = authSlice.actions


export default authSlice.reducer;