import React from 'react'
import DashHeader from '../components/DashHeader'
import { EmailTable } from '../components/EmailTable'
import Sidebar from '../components/Sidebar'

export const NewsLetterEmail = () => {
    return (
        <div className="">
            <DashHeader />
            <Sidebar />
            <div className="p-4 pt-20 sm:ml-64  ">
                <div className="p-4 ">
                    <EmailTable />
                </div>
            </div>
        </div>
    )
}
