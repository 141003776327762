import React from 'react'
import secbg from "../images/secbg.webp"
export const JoinPage = () => {
    function scrollToSection2(event) {
        event.preventDefault();
        let section = document.getElementById('contactus');
        section.scrollIntoView({ behavior: 'smooth' });
    }
    return (
        <section>
            <div className="bk_section_join" style={{ backgroundImage: `url(${secbg}) ` }}>
                <div className="container mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-2 justify-center">
                        <div className="col-span-1 md:col-span-2">
                            <div className="bk_join_content">
                                <h2 data-aos="fade-up" data-aos-delay="100" className="aos-init aos-animate">
                                    Let's publish for you!
                                </h2>
                                <p data-aos="fade-up" data-aos-delay="150" className="aos-init aos-animate">
                                    Wide range of business services to publish your game globally
                                </p>
                                <div data-aos="fade-up" data-aos-delay="200" className="aos-init aos-animate">
                                    <button href="#" onClick={scrollToSection2} className="btn btn_yallow btn_dt btn_anim">
                                        Submit your game
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
