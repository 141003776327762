import React from "react";
import { Link,  } from "react-router-dom";
import logo from "../../images/logo.svg"
function DashHeader() {
    return (
        <header className=" fixed w-full z-10 bg-slate-800">
            <nav className="bg-slate-800 border-gray-200 px-2 sm:px-4 py-2.5    border-b-2 border-black dark:border-white ">
                <div className="container flex flex-wrap items-center justify-between mx-auto text-white">
                    <Link to="/" className="flex items-center">
                        <img
                            src={logo}
                            className="h-full mr-3 sm:h-9"
                            alt="Aqua GamesLogo"
                            style={{ height: "45px", width: "45px" }}
                        />
                        <span className="logotitle self-center text-xl font-semibold whitespace-nowrap ">
                            Aqua Games
                        </span>
                    </Link>
                </div>

            </nav>
        </header >
    );
}

export default DashHeader;
