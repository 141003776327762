import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getPosts } from '../features/postsSlice';
import { PaginationBlogs } from './PaginationBlogs';
import dateFormat from 'dateformat';

export const BlogCards = () => {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(4);

    useEffect(() => {
        dispatch(getPosts());
    }, [dispatch]);

    const posts = useSelector((state) => state.posts.posts);
    const filterPost = posts.filter((post) => post.category === 'Blog');
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = filterPost.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);


    return (
        <div>
            {filterPost.length > 0 ? ( // Check if there are any 'Blog' posts
                <aside aria-label="Related articles" className="pt-4 py-8 lg:py-24 bg-gray-50 ">
                    <div className="px-4 mx-auto max-w-screen-xl ">
                        <h2 className="mt-8 mb-8 text-2xl font-bold text-gray-900 ">Blogs</h2>
                        <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-4">
                            {currentPosts.map((i) => (
                                <Link to={`/blog/${i.blogurl}`} key={i.id} className="">
                                    <div className="card">
                                        <div className="card__header">
                                            <img src={i.imageUrl} alt={i.altname} className="card__image" width="600" />
                                        </div>
                                        <div className="card__body">
                                            <h4>{i.title}</h4>
                                            <p className=" overflow-hidden  mt-auto flex-grow line-clamp-3" dangerouslySetInnerHTML={{ __html: i.content }}></p>
                                        </div>
                                        <div className="card__footer">
                                            <div className="user">
                                                <div className="user__info">
                                                    <small> {dateFormat(
                                                        i.createdAt,
                                                        "mmmm dS, yyyy"
                                                    )}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                        <PaginationBlogs
                            postsPerPage={postsPerPage}
                            totalPosts={filterPost.length}
                            paginate={paginate}
                            currentPage={currentPage}
                        />
                    </div>
                </aside>
            ) : null}
        </div>
    );
};
