import React from 'react';
import AddPostForm from '../AddPostForm';
import DashHeader from '../components/DashHeader';
import Sidebar from '../components/Sidebar';


const Dashboard = () => {
    return (
        <div className="">
            <DashHeader/>
            <Sidebar />
            <div className="p-4 pt-20 sm:ml-64 ">
                <div className="p-4 ">
                    <AddPostForm />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
