import { combineReducers } from 'redux';
import authReducer from '../features/authSlice';
import postsReducer from '../features/postsSlice';
import cateReducer from '../features/cateSlice';
import partnerReducer from '../features/partnerSlice';
import uiReducer from '../features/uiSlice';
import spinReducer from '../features/SpinnerSlice';
import subscribeReducer from '../features/subscribeSlice';
import visitorReducer from '../features/incrementVisitorCount ';
const rootReducer = combineReducers({
  auth: authReducer,
  posts: postsReducer,
  si: spinReducer,
  ui: uiReducer,
  subscribe: subscribeReducer,
  visitor: visitorReducer,
  cate: cateReducer,
  partner:partnerReducer
});

export default rootReducer;
