// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { uiActions } from "../../features/uiSlice";
// export const Notification = ({ type, message }) => {
//     const notification = useSelector((state) => state.ui.notification);
//     const dispatch = useDispatch();

//     const handleClose = () => {
//         dispatch(
//             uiActions.showNotification({
//                 open: false,
//             })
//         );
//     };
//     useEffect(() => {
//         if (notification.open) {
//             const timer = setTimeout(() => {
//                 dispatch(
//                     uiActions.showNotification({
//                         open: false,
//                     })
//                 );
//             }, 5000);

//             return () => {
//                 clearTimeout(timer);
//             };
//         }
//     }, [dispatch, notification.open]);
//     return (
//         <div>
//             {notification.open &&
//                 <div>
//                     {type === "error" ? (
//                         <div id="alert-border-2" className="flex fixed z-20 top-0 left-0 w-full p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400  dark:border-red-800" role="alert">
//                             <svg className="flex-shrink-0 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
//                             <div className="ml-3 text-sm font-medium">
//                                 {message}
//                             </div>
//                             <button type="button" onClick={handleClose} className="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8  dark:text-red-400 " data-dismiss-target="#alert-border-2" aria-label="Close">
//                                 <span className="sr-only">Dismiss</span>
//                                 <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
//                             </button>
//                         </div>
//                     ) : type === "success" ? (
//                         <div id="alert-border-3" className="flex fixed z-20 top-0 left-0 w-full p-4 mb-4 text-green-800 border-t-4 border-green-300 bg-green-50 dark:text-green-400  dark:border-green-800" role="alert">
//                             <svg className="flex-shrink-0 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
//                             <div className="ml-3 text-sm font-medium">
//                                 {message}
//                             </div>
//                             <button type="button" onClick={handleClose} className="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8  dark:text-green-400 " data-dismiss-target="#alert-border-3" aria-label="Close">
//                                 <span className="sr-only">Dismiss</span>
//                                 <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
//                             </button>
//                         </div>
//                     )
//                         : type === "warning" ? (
//                             <div id="alert-border-4" className="flex fixed z-20 top-0 left-0 w-full p-4 mb-4 text-yellow-800 border-t-4 border-yellow-300 bg-yellow-50 dark:text-yellow-300  dark:border-yellow-800" role="alert">
//                                 <svg className="flex-shrink-0 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
//                                 <div className="ml-3 text-sm font-medium">
//                                     {message}
//                                 </div>
//                                 <button type="button" onClick={handleClose} className="ml-auto -mx-1.5 -my-1.5 bg-yellow-50 text-yellow-500 rounded-lg focus:ring-2 focus:ring-yellow-400 p-1.5 hover:bg-yellow-200 inline-flex h-8 w-8  dark:text-yellow-300 " data-dismiss-target="#alert-border-4" aria-label="Close">
//                                     <span className="sr-only">Dismiss</span>
//                                     <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
//                                 </button>
//                             </div>
//                         ) : null



//                     }
//                 </div>




//             }
//         </div>
//     )
// };

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../features/uiSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Notification = ({ type, message }) => {
  const notification = useSelector((state) => state.ui.notification);
  const dispatch = useDispatch();

  useEffect(() => {
    if (notification.open) {
      const timer = setTimeout(() => {
        dispatch(uiActions.showNotification({ open: false }));
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [dispatch, notification.open]);

  useEffect(() => {
    if (notification.open && message) {
      const toastOptions = {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      };

      switch (type) {
        case "error":
          toast.error(message, toastOptions);
          break;
        case "success":
          toast.success(message, toastOptions);
          break;
        case "warning":
          toast.warning(message, toastOptions);
          break;
        default:
          break;
      }
    }
  }, [notification.open, message, type]);

  return <ToastContainer />;
};

export default Notification;
