import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getPostsByCategory } from '../../features/postsSlice';
import Footer from '../../partials/Footer';
import dateFormat from 'dateformat';
import Header from '../../partials/Header';
import "../../partials/blogCard.css"
import { PaginationBlogs } from '../../partials/PaginationBlogs';
export const CategoryFilterBlogs = () => {
    const dispatch = useDispatch();
    const { category } = useParams();
    const posts = useSelector(state => state.posts.posts);
    const isLoading = useSelector(state => state.posts.isLoading);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(4);
    useEffect(() => {

        dispatch(getPostsByCategory(category));
    }, [dispatch, category]);

    if (isLoading || !posts) {
        return <div className=''>Loading...</div>;
    }




    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div>
            <div className="flex flex-col min-h-screen overflow-hidden">
                <Header />
                <main className="flex-grow  text-base">
                    <div className="px-4 mx-auto  mb-4 max-w-screen-xl">
                        <h2 className="mt-8 mb-8 text-2xl font-bold text-gray-900 ">Blogs</h2>
                        <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-4">
                            {currentPosts.length > 0 ? (
                                currentPosts.map(i => (
                                    <Link to={`/blog/${i.blogurl}`} key={i.id} className="">
                                            <div className="card">
                                                <div className="card__header">
                                                    <img src={i.imageUrl} alt={i.altname} className="card__image" width="600" />
                                                </div>
                                                <div className="card__body">
                                                    {/* <span className="tag tag-blue">{i.category}</span> */}
                                                    <h4>{i.title}</h4>
                                                    <p className=" overflow-hidden  mt-auto flex-grow line-clamp-3" dangerouslySetInnerHTML={{ __html: i.content }}></p>
                                                </div>
                                                <div className="card__footer">
                                                    <div className="user">
                                                        {/* <img src={i.profileImage} alt="user image" className="user__image" />
                                                        <div className="user__info">
                                                            <h5>{i.userName}</h5> */}
                                                            <small> {dateFormat(
                                                                i.createdAt,
                                                                "mmmm dS, yyyy"
                                                            )}</small>
                                                        {/* </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                    </Link>
                                ))


                            ) : (
                                <p className=''>No results found.</p>
                            )}
                        </div>
                        <PaginationBlogs postsPerPage={postsPerPage}
                            totalPosts={posts.length}
                            paginate={paginate}
                            currentPage={currentPage} />
                    </div>
                </main>
                <Footer />
            </div>
        </div>
    )
}
