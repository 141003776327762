// src/app/App.jsx
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createPost } from '../features/postsSlice';
import JoditEditor from 'jodit-react';
import { useEffect, useRef, useState } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from '../firebase';
import { useDropzone } from 'react-dropzone';
import { siActions } from '../features/SpinnerSlice';
import { useNavigate } from 'react-router-dom';
import { getCate } from '../features/cateSlice';

function AddPostForm() {
    const cate = useSelector(state => state.cate.cate);
    const dispatch = useDispatch();
    const editor = useRef(null);
    const [file, setFile] = useState(null);
    // const [tags, setTags] = useState([]);
    const [content, setContent] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedType, setSelectedType] = useState('');

    const [imageUrl, setImageUrl] = useState("")
    const [disable, setDisable] = useState(true);
    const { user } = useSelector((state) => state.auth);
    const [mode, setMode] = useState('blog');
    useEffect(() => {
        // if (mode === 'blog') {
        //     setDisable(true);
        // }
        // else{

        // }
    }, [])
    useEffect(() => {
        if (!user || !user.userData || !user.userData.uid) {
            window.location.reload();
        }
        dispatch(getCate());

    }, [dispatch, user]);
    const navigate = useNavigate();
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
    } = useDropzone({
        accept: 'image/*',
        maxFiles: 1, // Only allow one file to be uploaded
        onDrop: (acceptedFiles) => {
            setFile(acceptedFiles[0]);
        },
    });
    useEffect(() => {
        const uploadFile = () => {

            try {
                dispatch(
                    siActions.showSpinner({
                        open2: true,
                    })
                );

                const storageRef = ref(storage, file.name);
                const uploadTask = uploadBytesResumable(storageRef, file);
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        // setProgress(
                        //     (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        // );
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                        if (progress === 100) {
                            dispatch(
                                siActions.showSpinner({
                                    open2: false,
                                })
                            );
                        }

                    },

                    (error) => {

                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            setDisable(false)
                            setImageUrl(downloadURL);
                        });
                    }
                );
            } catch (err) {
                dispatch(
                    siActions.showSpinner({
                        open2: false,
                    })
                );
            }
        };
        file && uploadFile();
    }, [file, dispatch]);
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    }
    const handleSelectTypeChange = (event) => {
        setSelectedType(event.target.value);
    }

    const handleReset = () => {
        setFile(null);
    };
    const handleSubmit = e => {
        e.preventDefault();
        try {
            let altname = e.target.altname.value;
            let title = e.target.title.value;
            let blogurl = e.target.blogurl.value;
            let category = selectedOption;
            let metatitle = e.target.metatitle.value;
            let metadescription = e.target.metadescription.value;
            let metakeyword = e.target.metakeyword.value;
            let userid = user.userData.uid;
            let profileImage = user.userData.profileImage;
            let jobTitle = user.userData.jobTitle;
            let userName = user.userData.userName;
            let sType = selectedType;
            let playstoreurl = e.target.playstoreurl.value;
            let appstoreurl = e.target.appstoreurl.value;
            let publishwith = e.target.publishwith.value
            dispatch(createPost(altname, title, blogurl, category, content, imageUrl, metatitle, metadescription, metakeyword, navigate, userid, profileImage, jobTitle, userName, sType, playstoreurl, appstoreurl,publishwith, mode));

        } catch (err) {

        }
    };
    const handleModeChange = (e) => {
        setMode(e.target.value);
    };
    return (
        <form className='p-1 md:p-10' onSubmit={handleSubmit}>
            <div className="mb-6">
                <label htmlFor="mode" className="block mb-2 text-sm font-medium text-gray-900 ">Change Mode</label>
                <select id="mode" value={mode} onChange={handleModeChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 ">
                    <option value="blog">Post Mode</option>
                    <option value="career">Career Mode</option>
                </select>
            </div>
            {mode === 'career' && (<div className="mb-6">
                <label htmlFor="type" className="block mb-2 text-sm font-medium text-gray-900 ">Select an option</label>
                <select defaultValue={"Job Type"} id="type" required disabled={disable} onChange={handleSelectTypeChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:opacity-50">
                    <option value="Remote">Remote</option>
                    <option value="Site">Site</option>
                    <option value="Hybrid">Hybrid</option>

                </select>
            </div>)}
            <div className="mb-6">
                <div>
                    <input {...getInputProps()} />
                    {file ? (
                        <div>
                            <div className="relative">
                                <img
                                    src={URL.createObjectURL(file)}
                                    alt="Uploaded file"
                                    className="h-full object-contain"
                                />
                                <button
                                    onClick={handleReset}
                                    className="absolute top-0 right-0 mt-2 mr-2 bg-white rounded-full p-2 hover:bg-gray-200 focus:outline-none"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>

                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="flex items-center justify-center w-full"  {...getRootProps()}>
                            <label htmlFor="dropzone-file" className={`flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800  hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 ${isDragActive
                                ? 'border-green-500 bg-green-100'
                                : 'border-gray-400 bg-gray-100'
                                } ${isDragReject ? 'border-red-500 bg-red-100' : ''}`}>
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                    <p className="mb-2 text-sm text-center  "><span className="font-semibold">Click to upload</span> {isDragActive ? 'Drop the image here' : 'Drag and drop an image here, or click to select one'}</p>
                                    <p className="text-xs  ">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                </div>
                            </label>
                        </div>

                    )}
                </div>
            </div>
            <div className="mb-6">
                <label htmlFor="altname" className="block mb-2 text-sm font-medium text-gray-900 ">Alt Name</label>
                <input type="text" required disabled={disable} name="altname" id="altname" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:opacity-50" placeholder="" />
            </div>
            <div className="mb-6">
                <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 ">Title</label>
                <input type="text" required disabled={disable} name="title" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:opacity-50" placeholder="" />
            </div>
            <div className="mb-6">
                <label htmlFor="blogurl" className="block mb-2 text-sm font-medium text-gray-900 ">Url</label>
                <input type="text" required disabled={disable} id="blogurl" name="blogurl" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:opacity-50" placeholder="" />
            </div>
            <div className="mb-6">
                <label htmlFor="cates" className="block mb-2 text-sm font-medium text-gray-900 ">Select an option</label>
                <select defaultValue={"Choose a Category"} id="cates" required disabled={disable} onChange={handleSelectChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    {cate.map(c => (
                        c.mode === mode && (
                            <option key={c.id} value={`${c.cate}`}>{c.cate}</option>)))}
                </select>
            </div>
            {mode === 'blog' && (
                <>
                    <div className="mb-6">
                        <label htmlFor="playstoreurl" className="block mb-2 text-sm font-medium text-gray-900 ">Play Store Url</label>
                        <input type="text"  disabled={disable} id="playstoreurl" name="playstoreurl" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:opacity-50" placeholder="" />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="appstoreurl" className="block mb-2 text-sm font-medium text-gray-900 ">App Store Url</label>
                        <input type="text"  disabled={disable} id="appstoreurl" name="appstoreurl" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:opacity-50" placeholder="" />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="publishwith" className="block mb-2 text-sm font-medium text-gray-900 ">Published With</label>
                        <input type="text" disabled={disable} id="publishwith" name="publishwith" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:opacity-50" placeholder="" />
                    </div>
                </>)}
            <div className="mb-6" >
                <JoditEditor
                    ref={editor}
                    value={content}
                    tabIndex={1}
                    required
                    onBlur={(newContent) => setContent(newContent)}
                    onChange={() => { }}
                    disabled={disable}
                />
            </div>
            <div className="mb-6">
                <label htmlFor="metatitle" className="block mb-2 text-sm font-medium text-gray-900 ">Meta Title</label>
                <input type="text" required disabled={disable} name="metatitle" id="metatitle" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:opacity-50" placeholder="" />
            </div>
            <div className="mb-6">
                <label htmlFor="metadescription" className="block mb-2 text-sm font-medium text-gray-900 ">Meta Descrption</label>
                <input type="text" required disabled={disable} name="metadescription" id="metadescription" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:opacity-50" placeholder="" />
            </div>
            <div className="mb-6">
                <label htmlFor="metakeyword" className="block mb-2 text-sm font-medium text-gray-900 ">Meta keyword</label>
                <input type="text" required disabled={disable} id="metakeyword" name='metakeyword' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:opacity-50" placeholder="" />
            </div>
            <div className="mb-6">
                <button disabled={disable} type="submit" className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2   dark:focus:ring-gray-700 disabled:opacity-50">Post</button>
            </div>
        </form>
    );
}

export default AddPostForm;
