import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getVisitorCount } from "../../features/incrementVisitorCount ";
import { CChart } from "@coreui/react-chartjs";

const VisitorGraph = () => {
  const dispatch = useDispatch();
  const visitorCount = useSelector((state) => state.visitor.visitor);
  const [year, setYear] = useState(new Date().getFullYear()); // add state for year

  useEffect(() => {
    dispatch(getVisitorCount());
  }, [dispatch]);

  const months = visitorCount ? Object.keys(visitorCount) : [];
  const filteredMonths = months.filter((month) => {
    const yearMonth = new Date(month).getFullYear();
    return yearMonth === year; // filter by year
  });

  const chartData = {
    labels: filteredMonths,
    datasets: [
      {
        label: "Visitor Count",
        backgroundColor: " rgb(20 40 60)",
        data: filteredMonths.map((month) => visitorCount[month]),
      },
    ],
    options: {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            fontColor: "white" // set y-axis tick color to white
          },
          gridLines: {
            color: "gray", // set y-axis grid line color to gray
            borderDash: [2],
            zeroLineColor: "gray"
          }
        }],
        xAxes: [{
          ticks: {
            fontColor: "white" // set x-axis tick color to white
          },
          gridLines: {
            color: "gray", // set x-axis grid line color to gray
            borderDash: [2],
            zeroLineColor: "gray"
          }
        }]
      },
      legend: {
        labels: {
          fontColor: "white" // set legend label color to white
        }
      }
    }
  };

  const handleYearChange = (change) => {
    if (change === "prev") {
      setYear(year - 1);
    } else if (change === "next") {
      setYear(year + 1);
    }
  };

  return (
    <div className=" mx-auto h-3/6  mt-4   dark:border-gray-100">
      <button onClick={() => handleYearChange("prev")} className="mr-2">
        {"<"}
      </button>
      <label htmlFor="year-select">
        {year}
      </label>
      <button onClick={() => handleYearChange("next")} className="ml-2">
        {">"}
      </button>
      <CChart type="bar" data={chartData} />
    </div>
  );
};

export default VisitorGraph;
