import { createSlice } from "@reduxjs/toolkit";

const siSlice = createSlice({
    name: "si",
    initialState: { spinner: null },
    reducers: {
        showSpinner(state, action) {
            state.spinner = {
                message: action.payload.message,
                type: action.payload.type,
                open2: action.payload.open2,
            };
        },
    },
});
export const siActions = siSlice.actions;
export default siSlice.reducer;
