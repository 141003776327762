import { addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../firebase';
import { siActions, } from './SpinnerSlice';
import { uiActions } from './uiSlice';
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    isLoading: false,
    error: null,
    cate: []
};

export const getCateSuccess = cate => ({
    type: 'cate/getCateSuccess',
    payload: cate
});

export const deleteCateSuccess = id => ({
    type: 'cate/deleteCateSuccess',
    payload: { id }
});

export const updateCateSuccess = updatedCate => ({
    type: 'cate/updateCateSuccess',
    payload: updatedCate
});


const cateSlice = createSlice({
    name: 'cate',
    initialState,
    reducers: {
        startLoading: state => {
            state.isLoading = true;
        },
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        createCateSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.cate.push(action.payload);
        },
        getCateSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.cate = action.payload;
        },
        deleteCateSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.cate = state.cate.filter(item => item.id !== action.payload.id);
        },
        updateCateSuccess(state, action) {
            state.cate = state.cate.map((category) => {
                if (category.id === action.payload.id) {
                    return action.payload;
                }
                return category;
            });
        },


    }
});

export const createCate = (cate, mode) => async dispatch => {
    dispatch(startLoading());
    dispatch(
        uiActions.showNotification({
            open: true,
            message: 'Sending Request',
            type: 'warning'
        })
    );
    try {
        // Check if category already exists in Firestore
        const querySnapshot = await getDocs(
            query(collection(db, 'cate'), where('cate', '==', cate))
        );
        if (!querySnapshot.empty) {
            dispatch(
                uiActions.showNotification({
                    open: true,
                    message: 'Category already exists.',
                    type: 'error'
                })
            );
            throw new Error('Category already exists.');
        }
        const newPostRef = await addDoc(collection(db, 'cate'), {
            cate,
            mode,
            createdAt: Date.now()
        });
        const newPost = {
            id: newPostRef.id,
            cate,
            mode,
            createdAt: Date.now()
        };
        dispatch(createCateSuccess(newPost));

        dispatch(
            uiActions.showNotification({
                open: true,
                message: 'Category Added successfully',
                type: 'success'
            })
        );
    } catch (error) {
        dispatch(hasError(error.message));
        dispatch(
            uiActions.showNotification({
                open: true,
                message: error.message,
                type: 'error'
            })
        );
    }
};

export const getCate = () => async dispatch => {
    dispatch(startLoading());
    dispatch(siActions.showSpinner({ open2: true }));
    try {
        const querySnapshot = await getDocs(collection(db, 'cate'));
        const cate = [];
        querySnapshot.forEach(doc => {
            cate.push({ id: doc.id, ...doc.data() });
        });
        dispatch(getCateSuccess(cate));
        dispatch(siActions.showSpinner({ open2: false }));
    } catch (error) {
        dispatch(hasError(error.message));
        dispatch(siActions.showSpinner({ open2: false }));
    }
};

export const deleteCate = id => async dispatch => {
    dispatch(startLoading());
    try {
        await deleteDoc(doc(collection(db, 'cate'), id));
        dispatch(deleteCateSuccess(id));
        dispatch(
            uiActions.showNotification({
                open: true,
                message: 'Category deleted successfully',
                type: 'success'
            })
        );
    } catch (error) {
        dispatch(hasError(error.message));
        dispatch(
            uiActions.showNotification({
                open: true,
                message: error.message,
                type: 'error'
            })
        );
    }
};

export const updateCate = (did, cate, mode) => async dispatch => {
    dispatch(startLoading());
    dispatch(
        uiActions.showNotification({
            open: true,
            message: 'Updating Category',
            type: 'warning'
        })
    );
    try {
        const cateRef = doc(db, 'cate', did);
        await updateDoc(cateRef, {
            cate: cate,
            mode,
            updatedAt: Date.now()
        });
        const updatedPost = {
            did,
            cate: cate,
            mode,
            updatedAt: Date.now()
        };
        dispatch(updateCateSuccess(updatedPost));

        dispatch(
            uiActions.showNotification({
                open: true,
                message: 'Category Updated successfully',
                type: 'success'
            })
        );
    } catch (error) {
        dispatch(hasError(error.message));
        dispatch(
            uiActions.showNotification({
                open: true,
                message: error.message,
                type: 'error'
            })
        );
    }
};




export const { startLoading, hasError, createCateSuccess } = cateSlice.actions;
export default cateSlice.reducer;