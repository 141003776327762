import React from 'react'
import DashHeader from '../components/DashHeader'
import Sidebar from '../components/Sidebar'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDropzone } from 'react-dropzone';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { siActions } from '../../features/SpinnerSlice'
import { storage } from '../../firebase'
import { createpartner, deletepartner, getpartner, updatepartner } from '../../features/partnerSlice'

export const AddPartner = () => {
    const partner = useSelector(state => state.partner.partner);
    const dispatch = useDispatch();
    const [updateMode, setUpdateMode] = useState(false);
    const [upID, setUpID] = useState(null);
    const [file, setFile] = useState(null);
    const [newName, setNewName] = useState("");
    const [newDesc, setNewDesc] = useState("");
    const [imageUrl, setImageUrl] = useState("")
    const [imageUrlUpdated, setImageUrlUpdated] = useState("")
    const [disable, setDisable] = useState(true);
    useEffect(() => {
        dispatch(getpartner());
    }, [dispatch]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
    } = useDropzone({
        accept: 'image/*',
        maxFiles: 1, // Only allow one file to be uploaded
        onDrop: (acceptedFiles) => {
            setFile(acceptedFiles[0]);
        },
    });
    useEffect(() => {
        const uploadFile = () => {

            try {
                dispatch(
                    siActions.showSpinner({
                        open2: true,
                    })
                );

                const storageRef = ref(storage, file.name);
                const uploadTask = uploadBytesResumable(storageRef, file);
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        // setProgress(
                        //     (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        // );
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                        if (progress === 100) {
                            dispatch(
                                siActions.showSpinner({
                                    open2: false,
                                })
                            );
                        }

                    },

                    (error) => {

                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            setDisable(false)
                            setImageUrl(downloadURL);
                            console.log(downloadURL, "upload");
                        });
                    }
                );
            } catch (err) {
                dispatch(
                    siActions.showSpinner({
                        open2: false,
                    })
                );
            }
        };
        file && uploadFile();
    }, [file, dispatch]);

    const handleReset = () => {
        setFile(null);
    };
    const handleSubmit = e => {
        e.preventDefault();
        try {
            let name = e.target.name.value;
            let description = e.target.description.value
            dispatch(createpartner(name, description, imageUrl));


        } catch (err) {

        }
    };


    const handleSetUpdate = useCallback(
        (did, t, nname, ndesc, img) => {
            return async () => {
                setUpID(did);
                setUpdateMode(true);
                setNewName(nname);
                setNewDesc(ndesc);
                setImageUrlUpdated(img);
            };
        },
        []
    );


    const handleUpdate = (e) => {
        e.preventDefault();
        try {
            let name = e.target.name.value;
            let description = e.target.description.value
            dispatch(updatepartner(upID, name, description, imageUrl));
            setUpdateMode(false);
            dispatch(getpartner());

        } catch (err) {
            console.log(err, "err");
        }

    }
    const handleDelete = useCallback(
        (did) => async () => {
            dispatch(deletepartner(did));
        },
        [dispatch]
    );

    return (
        <div className="">
            <DashHeader />
            <Sidebar />
            <div className="p-4  sm:ml-64 ">
                <div className="p-4 mt-10 ">

                    {updateMode ?
                        (<form className='p-1 md:px-20 pt-4' onSubmit={handleUpdate}>
                            <div className="mb-6">
                                <div>
                                    <input {...getInputProps()} />
                                    {file ? (
                                        <div>
                                            <div className="relative">
                                                <img
                                                    src={URL.createObjectURL(file)}
                                                    alt="Uploaded file"
                                                    className="h-full object-contain"
                                                />
                                                <button
                                                    onClick={handleReset}
                                                    className="absolute top-0 right-0 mt-2 mr-2 bg-white rounded-full p-2 hover:bg-gray-200 focus:outline-none"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>

                                                </button>
                                            </div>
                                        </div>
                                    ) : imageUrlUpdated ? (
                                        <div>
                                            <div className="relative">
                                                <img
                                                    src={imageUrlUpdated}
                                                    alt="Uploaded file"
                                                    className="h-full object-contain"
                                                    style={{ height: "400px", width: "100%" }}
                                                />
                                                <button
                                                    onClick={() => setImageUrlUpdated(null)}
                                                    className="absolute top-0 right-0 mt-2 mr-2 bg-white rounded-full p-2 hover:bg-gray-200 focus:outline-none"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>

                                                </button>
                                            </div>
                                        </div>) : (
                                        <div className="flex items-center justify-center w-full"  {...getRootProps()}>
                                            <label htmlFor="dropzone-file" className={`flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800  hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 ${isDragActive
                                                ? 'border-green-500 bg-green-100'
                                                : 'border-gray-400 bg-gray-100'
                                                } ${isDragReject ? 'border-red-500 bg-red-100' : ''}`}>
                                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                                    <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                    <p className="mb-2 text-sm text-center  "><span className="font-semibold">Click to upload</span> {isDragActive ? 'Drop the image here' : 'Drag and drop an image here, or click to select one'}</p>
                                                    <p className="text-xs  ">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                                </div>
                                            </label>
                                        </div>

                                    )}
                                </div>
                            </div>
                            <div className="mb-6">
                                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 ">Name</label>
                                <input type="text" required name="name" id="name" defaultValue={newName} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                            </div>
                            <div className="mb-6">
                                <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 ">Description</label>
                                <input type="text" required name="description" id="description" defaultValue={newDesc} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                            </div>
                            <div className="mb-6">
                                <button type="submit" className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2   dark:focus:ring-gray-700 ">Update</button>

                                <button onClick={() => setUpdateMode(false)} className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2   dark:focus:ring-gray-700 ">Cancel</button>
                            </div>
                        </form>) : (
                            <form className='p-1 md:px-20 pt-4' onSubmit={handleSubmit}>
                                <div className="mb-6">
                                    <div>
                                        <input {...getInputProps()} />
                                        {file ? (
                                            <div>
                                                <div className="relative">
                                                    <img
                                                        src={URL.createObjectURL(file)}
                                                        alt="Uploaded file"
                                                        className="h-full object-contain"
                                                    />
                                                    <button
                                                        onClick={handleReset}
                                                        className="absolute top-0 right-0 mt-2 mr-2 bg-white rounded-full p-2 hover:bg-gray-200 focus:outline-none"
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>

                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="flex items-center justify-center w-full"  {...getRootProps()}>
                                                <label htmlFor="dropzone-file" className={`flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800  hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 ${isDragActive
                                                    ? 'border-green-500 bg-green-100'
                                                    : 'border-gray-400 bg-gray-100'
                                                    } ${isDragReject ? 'border-red-500 bg-red-100' : ''}`}>
                                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                                        <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        <p className="mb-2 text-sm text-center  "><span className="font-semibold">Click to upload</span> {isDragActive ? 'Drop the image here' : 'Drag and drop an image here, or click to select one'}</p>
                                                        <p className="text-xs  ">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                                    </div>
                                                </label>
                                            </div>

                                        )}
                                    </div>
                                </div>
                                <div className="mb-6">
                                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 ">Name</label>
                                    <input type="text" disabled={disable} required name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                </div>
                                <div className="mb-6">
                                    <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 ">Description</label>
                                    <input type="text" disabled={disable} required name="description" id="description" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                </div>
                                <div className="mb-6">
                                    <button type="submit" disabled={disable} className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2   dark:focus:ring-gray-700 ">Add</button>
                                </div>
                            </form>
                        )}

                </div>
                <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8 pt-0">
                    <div className="overflow-x-auto">
                        <div className="align-middle inline-block min-w-full shadow-lg rounded-lg overflow-hidden">
                            <table className="w-full text-sm text-left  ">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
                                    <tr>
                                        <th className="px-6 py-3 border-b border-gray-600 text-left text-xs leading-4 font-medium  uppercase tracking-wider">Partner Name</th>
                                        <th className="px-6 py-3 border-b border-gray-600 text-left text-xs leading-4 font-medium  uppercase tracking-wider">Description</th>
                                        <th className="px-6 py-3 border-b border-gray-600 text-left text-xs leading-4 font-medium  uppercase tracking-wider">Image</th>
                                        <th className="px-6 py-3 border-b border-gray-600 text-left text-xs leading-4 font-medium  uppercase tracking-wider">Update</th>
                                        <th className="px-6 py-3 border-b border-gray-600 text-left text-xs leading-4 font-medium  uppercase tracking-wider">Delete</th>
                                    </tr>
                                </thead>
                                <tbody >

                                    {partner && partner.map(i => (
                                        <tr className="text-gray-900 bg-white border-b   text-white" key={i.id}>
                                            <td className="px-6 py-4 ">
                                                <div className="flex items-center">
                                                    <div className="ml-4">
                                                        <div className="text-sm leading-5 font-medium text-gray-900  ">{i.name}</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 ">
                                                <div className="flex items-center">
                                                    <div className="ml-4">
                                                        <div className="text-sm leading-5 font-medium text-gray-900  ">{i.description}</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 ">
                                                <div className="flex items-center">
                                                    <div className="ml-4">
                                                        <img src={i.imageUrl} alt={i.name} style={{ width: "50px", height: "50px" }} />
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 ">
                                                <button onClick={handleSetUpdate(i.id, true, i.name, i.description, i.imageUrl)} ><span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-400 text-gray-800">Update</span></button>
                                            </td>
                                            <td className="px-6 py-4 ">
                                                <button className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-400 text-gray-800" onClick={handleDelete(i.id)}>Delete</button>
                                            </td>
                                        </tr>)

                                    )}



                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div >
    )
}
