import React from 'react'
import VisitorGraph from './VisitorGraph'
import DashHeader from '../components/DashHeader'
import Sidebar from '../components/Sidebar'


export const VisitorCount = () => {
    return (
        <div className="">
            <DashHeader />
            <Sidebar />
            <div className="p-4  sm:ml-64 ">
                <div className="p-4 mt-10 ">
                    <VisitorGraph />
                </div>
            </div>
        </div>
    )
}
