import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const Spinner = ({ type, message }) => {
    const spinner = useSelector((state) => state.si.spinner);
    const [loadingProgress, setLoadingProgress] = useState(0);

    useEffect(() => {
        // Simulating loading progress
        if (spinner?.open2) {
            setLoadingProgress(0);
            const interval = setInterval(() => {
                setLoadingProgress((prevProgress) => {
                    const newProgress = prevProgress + 10;
                    if (newProgress >= 100) {
                        clearInterval(interval);
                        return 100;
                    } else {
                        return newProgress;
                    }
                });
            }, 500);
        }
    }, [spinner]);

    return (
        <div>
            {spinner?.open2 && (
                <div className="w-full bg-gray-200 rounded-full h-1.5  fixed top-0 left-0 z-20">
                    <div className="bg-purple-600 h-1.5 rounded-full dark:bg-purple-500" style={{ width: loadingProgress + "%" }}></div>
                </div>
            )}
        </div>
    );
};
