import { addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../firebase';
import { siActions, } from './SpinnerSlice';
import { uiActions } from './uiSlice';
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    isLoading: false,
    error: null,
    partner: []
};

export const getpartnerSuccess = partner => ({
    type: 'partner/getpartnerSuccess',
    payload: partner
});

export const deletepartnerSuccess = id => ({
    type: 'partner/deletepartnerSuccess',
    payload: { id }
});

export const updatepartnerSuccess = updatedpartner => ({
    type: 'partner/updatepartnerSuccess',
    payload: updatedpartner
});


const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        startLoading: state => {
            state.isLoading = true;
        },
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        createpartnerSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.partner.push(action.payload);
        },
        getpartnerSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.partner = action.payload;
        },
        deletepartnerSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.partner = state.partner.filter(item => item.id !== action.payload.id);
        },
        updatepartnerSuccess(state, action) {
            state.partner = state.partner.map((partner) => {
                if (partner.id === action.payload.id) {
                    return action.payload;
                }
                return partner;
            });
        },


    }
});

export const createpartner = (name, description, imageUrl) => async dispatch => {
    dispatch(startLoading());
    dispatch(
        uiActions.showNotification({
            open: true,
            message: 'Sending Request',
            type: 'warning'
        })
    );
    try {
        // Check if partner already exists in Firestore
        const querySnapshot = await getDocs(
            query(collection(db, 'partner'), where('name', '==', name))
        );
        if (!querySnapshot.empty) {
            dispatch(
                uiActions.showNotification({
                    open: true,
                    message: 'partner already exists.',
                    type: 'error'
                })
            );
            throw new Error('partner already exists.');
        }
        const newPostRef = await addDoc(collection(db, 'partner'), {
            name,
            description,
            imageUrl,
            createdAt: Date.now()
        });
        const newPost = {
            id: newPostRef.id,
            name,
            description,
            imageUrl,
            createdAt: Date.now()
        };
        dispatch(createpartnerSuccess(newPost));

        dispatch(
            uiActions.showNotification({
                open: true,
                message: 'partner Added successfully',
                type: 'success'
            })
        );
    } catch (error) {
        dispatch(hasError(error.message));
        dispatch(
            uiActions.showNotification({
                open: true,
                message: error.message,
                type: 'error'
            })
        );
    }
};

export const getpartner = () => async dispatch => {
    dispatch(startLoading());
    dispatch(siActions.showSpinner({ open2: true }));
    try {
        const querySnapshot = await getDocs(collection(db, 'partner'));
        const partner = [];
        querySnapshot.forEach(doc => {
            partner.push({ id: doc.id, ...doc.data() });
        });
        dispatch(getpartnerSuccess(partner));
        dispatch(siActions.showSpinner({ open2: false }));
    } catch (error) {
        dispatch(hasError(error.message));
        dispatch(siActions.showSpinner({ open2: false }));
    }
};

export const deletepartner = id => async dispatch => {
    dispatch(startLoading());
    try {
        await deleteDoc(doc(collection(db, 'partner'), id));
        dispatch(deletepartnerSuccess(id));
        dispatch(
            uiActions.showNotification({
                open: true,
                message: 'partner deleted successfully',
                type: 'success'
            })
        );
    } catch (error) {
        dispatch(hasError(error.message));
        dispatch(
            uiActions.showNotification({
                open: true,
                message: error.message,
                type: 'error'
            })
        );
    }
};

export const updatepartner = (did, name, description, imageUrl) => async dispatch => {
    dispatch(startLoading());
    dispatch(
        uiActions.showNotification({
            open: true,
            message: 'Updating partner',
            type: 'warning'
        })
    );
    try {
        const partnerRef = doc(db, 'partner', did);
        await updateDoc(partnerRef, {
            name: name,
            description: description,
            imageUrl: imageUrl,
            updatedAt: Date.now()
        });
        const updatedPost = {
            did,
            name: name,
            description: description,
            imageUrl: imageUrl,
            updatedAt: Date.now()
        };
        dispatch(updatepartnerSuccess(updatedPost));

        dispatch(
            uiActions.showNotification({
                open: true,
                message: 'partner Updated successfully',
                type: 'success'
            })
        );
    } catch (error) {
        dispatch(hasError(error.message));
        dispatch(
            uiActions.showNotification({
                open: true,
                message: error.message,
                type: 'error'
            })
        );
    }
};




export const { startLoading, hasError, createpartnerSuccess } = partnerSlice.actions;
export default partnerSlice.reducer;