import React from 'react';
import bimg1 from "../images/bimg1.png"
import s1 from "../images/shape-one.svg"
import s2 from "../images/shape-two.svg"
import s3 from "../images/shape-three.svg"
import s4 from "../images/shape-four.svg"
import s5 from "../images/shape-five.svg"
import Typewriter from "typewriter-effect";
function HeroHome() {
  return (

<section className="">
  <div className="container flex flex-col justify-center p-6 mx-auto sm:py-12 lg:py-24 lg:flex-row lg:justify-between">
    <div className="flex flex-col justify-start mt-6 p-6 text-center rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
      <h1 className="text-5xl font-bold leading-none sm:text-6xl aos-init aos-animate" data-aos="fade-up" data-aos-delay="150">
        <Typewriter
          className="typingText"
          options={{
            strings: [
              "Games always and forever",
              "That Entertains",
              "Always and Forever",
            ],
            autoStart: true,
            loop: true,
          }}
        />
      </h1>
      <p className="mt-6 mb-8 text-lg sm:mb-12 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">We're a video game development house. Our team has worked on dozens of games on PC,
        <br className="hidden md:inline lg:hidden" /> console, and mobile platforms.
      </p>
      <div className="flex flex-col space-y-4 sm:items-center sm:justify-center sm:flex-row sm:space-y-0 sm:space-x-4 lg:justify-start">
        <div data-aos="fade-up" data-aos-delay="250" className="aos-init aos-animate">
          <a href="#about-section" className="btn btn_black btn_anim">Learn More! <span></span><span></span><span></span><span></span></a>
        </div>
      </div>
    </div>
    <div className="hidden lg:flex items-center justify-center p-6 mt-8 lg:mt-0">
      <div className="relative">
        <img src={bimg1} alt="banner img" className="object-fit  hidden lg:block" style={{height:"700px",width:"600px"}}/>
        <div className="img-shape shape-one">
          <img src={s1} alt="shape-one" data-aos="zoom-out" data-aos-delay="500" data-aos-duration="5000" className="aos-init aos-animate"style={{width:"28px",height:"28px"}} />
        </div>
        <div className="img-shape shape-two">
          <img src={s2} alt="shape-two" data-aos="zoom-out" data-aos-delay="500" data-aos-duration="5000" className="aos-init aos-animate"style={{width:"48px",height:"48px"}} />
        </div>
        <div className="img-shape shape-three">
          <img src={s3} alt="shape-three" data-aos="zoom-out" data-aos-delay="500" data-aos-duration="5000" className="aos-init aos-animate" style={{width:"58px",height:"58px"}} />
        </div>
        <div className="img-shape shape-four">
          <img src={s4} alt="shape-four" data-aos="zoom-out" data-aos-delay="500" data-aos-duration="5000" className="aos-init aos-animate"style={{width:"30px",height:"30px"}} />
        </div>
        <div className="img-shape shape-five">
          <img src={s5} alt="shape-five" data-aos="zoom-out" data-aos-delay="500" data-aos-duration="5000" className="aos-init aos-animate" style={{width:"90px",height:"90px"}}/>
        </div>
      </div>
    </div>
  </div>
</section>

  );
}

export default HeroHome;