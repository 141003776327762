import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { createJob, getPostByUrl } from '../features/postsSlice';
import Header from '../partials/Header';
import dateFormat from 'dateformat';
import Footer from '../partials/Footer';
import { storage } from '../firebase';
import { useState } from 'react';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { siActions } from '../features/SpinnerSlice';

export const SingleCareerDetails = () => {
    const dispatch = useDispatch();
    const { url } = useParams();
    const post = useSelector(state => state.posts.post);
    // const isLoading = useSelector(state => state.posts.isLoading);
    const { user } = useSelector((state) => state.auth);
    const [file, setFile] = useState(null);
    const [disable, setDisable] = useState(true);
    const [imageUrl, setImageUrl] = useState("");

    const handleFileChange = (e) => {
        e.preventDefault();
        setFile(e.target.files[0]);
    };
    function scrollToSection(event) {
        event.preventDefault();
        let section = document.getElementById('applysec');
        section.scrollIntoView({ behavior: 'smooth' });
    }

    useEffect(() => {
        const uploadFile = () => {
            try {
                dispatch(siActions.showSpinner({ open2: true }));
                const storageRef = ref(storage, file.name);
                const uploadTask = uploadBytesResumable(storageRef, file);
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                        if (progress === 100) {
                            dispatch(siActions.showSpinner({ open2: false }));
                        }
                    },
                    (error) => {
                        // Handle upload error
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            setDisable(false);
                            setImageUrl(downloadURL);
                        });
                    }
                );
            } catch (err) {
                dispatch(siActions.showSpinner({ open2: false }));
            }
        };

        file && uploadFile();
    }, [file, dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();

        try {
            let email = e.target.email.value;
            let name = e.target.name.value;
            let number = e.target.number.value;
            let message = e.target.message.value;
            let userid = user.userData.uid;

            dispatch(createJob(email, name, number, message, url, imageUrl, userid));
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        dispatch(getPostByUrl(url));
    }, [dispatch,url]);

    return (
        <div className="">
            <Header />
            <div className="">
                {Array.isArray(post) && post.map(i => (
                    <div key={i.id}>
                        <Helmet>
                            <title>{i.metatitle}</title>
                            <meta name="description" content={i.metadescription} />
                            <meta name="keywords" content={i.metakeyword} />
                        </Helmet>
                        <div className="banner_careerDetails"><div className="container mx-auto">
                            <nav className="w-full rounded-md flex justify-center">
                                <ol className="list-reset flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                                    <li>
                                        <Link
                                            to="/"
                                            className="text-white transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                                        >Home</Link>
                                    </li>
                                    <li>
                                        <span className="mx-2 text-neutral-500 dark:text-neutral-400">/</span>
                                    </li>
                                    <li>
                                        <Link
                                            to={`/career/${i.blogurl}`}
                                            className="text-white transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                                        >Careers</Link>
                                    </li>
                                    <li>
                                        <span className="mx-2 text-neutral-500 dark:text-neutral-400">/</span>
                                    </li>
                                    <li className="text-neutral-500 dark:text-neutral-400">{i.blogurl}</li>
                                </ol>
                            </nav>


                            <div className="row text-center">
                                <div className="col-lg-7 col-md-10">
                                    <div className="banner_head_txt aos-init aos-animate" data-aos="fade-up" data-aos-delay="150"><h2>{i.sType}</h2><p><i className="fas fa-map-marker-alt"></i> {i.title}</p>
                                        <a href="#career_form" className="btn btn_yallow btn_anim" onClick={scrollToSection}>Apply now<span></span><span></span><span></span><span></span></a></div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="px-4 mt-4 mx-auto max-w-screen-xl ">
                            {user && i.userid === user.userData.uid ? (
                                <Link to={`/update/${i.id}/career`}><span className="px-4 py-2 mt-2 mb-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-400 text-gray-800">Update</span></Link>
                            ) : null}
                            <p className="text-base mb-4 font-light  "><time dateTime="2022-02-08" title="February 8th, 2022">
                                {dateFormat(
                                    i.createdAt,
                                    "mmmm dS, yyyy"
                                )}</time></p>
                            <div className="grid gap-12 sm:grid-cols-1 lg:grid-cols-2">

                                <div className="details_career aos-init aos-animate" data-aos="fade-up" data-aos-delay="150" dangerouslySetInnerHTML={{ __html: i.content }}>

                                </div>
                                <section className="bg-white applysec" id='applysec'>
                                    <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold  text-gray-900 ">Apply for this position</h2>
                                        <form className="space-y-8" onSubmit={handleSubmit}>
                                            <div>
                                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your email</label>
                                                <input type="email" id="email" name='email' className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="name@gmail.com" required />
                                            </div>
                                            <div>
                                                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Full Name</label>
                                                <input type="text" id="name" name='name' className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Enter Your Full Name" required />
                                            </div>
                                            <div>
                                                <label htmlFor="number" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Mobile Number</label>
                                                <input type="text" id="number" name='number' className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Enter Your Mobile Number" required />
                                            </div>
                                            <div className="sm:col-span-2">
                                                <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 ">What Would You Like Us To Know About You</label>
                                                <textarea id="message" name='message' rows="6" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Leave a comment..."></textarea>
                                            </div>
                                            <div className="attach_note flex align-items">
                                                <div className="attach_file grp_file">
                                                    <input type="file" onChange={handleFileChange} className="file_upload" name="attachFile" id="attachFile" data-parsley-filemaxsize="12 MB" required="" accept=".png,.jpg,.pdf,.PNG,.JPG,.JPEG,.jpeg,.HEIC,.heic" />
                                                    <div className="box_file_txt">
                                                    </div>
                                                    <p>
                                                        <i className="fal fa-paperclip"></i>Attach File
                                                    </p>
                                                </div>
                                                <p>Maximum size: 12 MB</p>
                                            </div>
                                            <button
                                                type="submit"
                                                className="btn btn_black ml-auto btn_anim my_pay_btn disabled:opacity-70"
                                                disabled={disable}
                                            >
                                                Apply Now
                                                <span></span><span></span><span></span><span></span>
                                            </button>


                                        </form>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>

                ))}
            </div>
            <Footer />
        </div>
    );


}
