// src/app/App.jsx
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import JoditEditor from 'jodit-react';
import { useEffect, useRef, useState } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useDropzone } from 'react-dropzone';
import { storage } from '../../firebase';
import { getCate, getPostById, updatePost } from '../../features/postsSlice';
import { useNavigate, useParams } from 'react-router-dom';
import DashHeader from '../components/DashHeader';
import Sidebar from '../components/Sidebar';
import { Spinner } from '../components/Spinner';
import { siActions } from '../../features/SpinnerSlice';
function UpdateBlog() {
    const dispatch = useDispatch();
    const cate = useSelector(state => state.cate.cate);
    // const [mode, setMode] = useState('blog');
    const editor = useRef(null);
    const [file, setFile] = useState(null);
    const [content, setContent] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [imageUrl, setImageUrl] = useState(null)
    const [disable, setDisable] = useState(true);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
    } = useDropzone({
        accept: 'image/*',
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            setFile(acceptedFiles[0]);
        },
    });
    useEffect(() => {

        const uploadFile = () => {
            try {
                dispatch(
                    siActions.showSpinner({
                        open2: true,
                    })
                );
                const storageRef = ref(storage, file.name);
                const uploadTask = uploadBytesResumable(storageRef, file);
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                        if (progress === 100) {
                            dispatch(
                                siActions.showSpinner({
                                    open2: false,
                                })
                            );
                        }

                    },

                    (error) => {

                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            setDisable(false)
                            setImageUrl(downloadURL);
                        });
                    }
                );
            } catch (err) {
                dispatch(
                    siActions.showSpinner({
                        open2: false,
                    })
                );
            }
        };
        file && uploadFile();
    }, [file, dispatch]);
    let navigate = useNavigate();
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    }
    const { postId, mode } = useParams();
    const post = useSelector(state => state.posts.post);
    const isLoading = useSelector(state => state.posts.isLoading);
    const [selectedType, setSelectedType] = useState(null);
    useEffect(() => {
        if (post) {
            setImageUrl(post.imageUrl);
        }
        dispatch(getCate());
    }, [dispatch, post, cate]);
    useEffect(() => {
        dispatch(getPostById(postId));
    }, [dispatch, postId]);

    if (isLoading || !post) {
        return <Spinner />;
    }
    const handleSelectTypeChange = (event) => {
        setSelectedType(event.target.value);
    }

    const handleSubmit = e => {
        e.preventDefault();
        try {
            const updatedPostData = {
                altname: e.target.altname.value,
                title: e.target.title.value,
                blogurl: e.target.blogurl.value,
                category: selectedOption !== null ? selectedOption : post.category,
                content: content,
                sType: selectedType !== null ? selectedType : post.sType,
                imageUrl: imageUrl,
                metatitle: e.target.metatitle.value,
                metadescription: e.target.metadescription.value,
                metakeyword: e.target.metakeyword.value,
                playstoreurl: e.target.playstoreurl.value,
                appstoreurl: e.target.appstoreurl.value,
                publishwith: e.target.publishwith.value
            };
            dispatch(updatePost(postId, updatedPostData, navigate, mode));
        } catch (err) {
            console.log(err, "err");
        }
    };
    const handleReset = () => {
        setFile(null);
    };
    const handleResetImageUrl = () => {
        setImageUrl(null)
    };
    return (
        <div className="">
            <DashHeader />
            <Sidebar />
            <div className="p-4 pt-20 sm:ml-64">
                <div className="p-4 ">
                    <form className='p-20' onSubmit={handleSubmit}>
                        <div className="mb-6">
                            <label htmlFor="mode" className="block mb-2  font-medium text-gray-900 ">Mode:{mode}</label>
                        </div>
                        {mode === 'career' && (<div className="mb-6">
                            <label htmlFor="type" className="block mb-2 text-sm font-medium text-gray-900 ">Select an option</label>
                            <select defaultValue={post.sType} id="type" required onChange={handleSelectTypeChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option value="Remote">Remote</option>
                                <option value="Site">Site</option>
                                <option value="Hybrid">Hybrid</option>

                            </select>
                        </div>)}
                        <div className="mb-6">
                            <div>
                                <input {...getInputProps()} />
                                {file ? (
                                    <div className='relative'>

                                        <img
                                            src={URL.createObjectURL(file)}
                                            alt="Uploaded file"
                                            className="h-full object-contain"
                                        />
                                        <button type="button" onClick={handleReset} className="absolute top-0 right-0 mt-2 mr-2 bg-white rounded-full p-2 hover:bg-gray-200 focus:outline-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                        </button>



                                    </div>
                                ) : (
                                    imageUrl ?
                                        <div className='relative'>
                                            <div>
                                                <img
                                                    src={imageUrl}
                                                    alt="Uploaded file"
                                                    className="h-full object-contain"
                                                />
                                                <button type="button" onClick={handleResetImageUrl} className="absolute top-0 right-0 mt-2 mr-2 bg-white rounded-full p-2 hover:bg-gray-200 focus:outline-none">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </button>

                                            </div>
                                        </div>
                                        :
                                        <div className="flex items-center justify-center w-full"  {...getRootProps()}>
                                            <label htmlFor="dropzone-file" className={`flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800  hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 ${isDragActive
                                                ? 'border-green-500 bg-green-100'
                                                : 'border-gray-400 bg-gray-100'
                                                } ${isDragReject ? 'border-red-500 bg-red-100' : ''}`}>
                                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                                    <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                    <p className="mb-2 text-sm text-center  "><span className="font-semibold">Click to upload</span> {isDragActive ? 'Drop the image here' : 'Drag and drop an image here, or click to select one'}</p>
                                                    <p className="text-xs  ">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                                </div>
                                            </label>
                                        </div>
                                )}
                            </div>
                        </div>
                        <div className="mb-6">
                            <label htmlFor="altname" className="block mb-2 text-sm font-medium text-gray-900 ">Alt Name</label>
                            <input type="text" required defaultValue={post.altname} name="altname" id="altname" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Title</label>
                            <input type="text" required defaultValue={post.title} name="title" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Url</label>
                            <input type="text" required defaultValue={post.blogurl} id="email" name="blogurl" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="cates" className="block mb-2 text-sm font-medium text-gray-900">Select an option</label>
                            <select defaultValue={"defaultOption"} id="cates" required onChange={handleSelectChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                {cate.map(c => (
                                    c.mode === mode && (
                                        <option key={c.id} value={`${c.cate}`}>{c.cate}</option>
                                    )
                                ))}
                            </select>
                        </div>
                        {mode === 'blog' && (
                            <>
                                <div className="mb-6">
                                    <label htmlFor="playstoreurl" className="block mb-2 text-sm font-medium text-gray-900 ">Play Store Url</label>
                                    <input type="text"  defaultValue={post.playstoreurl} id="playstoreurl" name="playstoreurl" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:opacity-50" placeholder="" />
                                </div>
                                <div className="mb-6">
                                    <label htmlFor="appstoreurl" className="block mb-2 text-sm font-medium text-gray-900 ">App Store Url</label>
                                    <input type="text"  defaultValue={post.appstoreurl} id="appstoreurl" name="appstoreurl" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:opacity-50" placeholder="" />
                                </div>
                                <div className="mb-6">
                                    <label htmlFor="publishwith" className="block mb-2 text-sm font-medium text-gray-900 ">Published With</label>
                                    <input type="text"  defaultValue={[post.publishwith]} id="publishwith" name="publishwith" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:opacity-50" placeholder="" />
                                </div>
                            </>)}
                        <div className="mb-6" >
                            <JoditEditor
                                ref={editor}
                                value={post.content}
                                tabIndex={1}
                                required
                                onChange={(newContent) => {
                                    if (newContent !== content) {
                                        setContent(newContent);
                                    }
                                }}

                            />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Meta Title</label>
                            <input type="text" required defaultValue={post.metatitle} name="metatitle" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Meta Descrption</label>
                            <input type="text" required defaultValue={post.metadescription} name="metadescription" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Meta keyword</label>
                            <input type="text" required defaultValue={post.metakeyword} id="email" name='metakeyword' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                        </div>
                        <div className="mb-6">
                            <button type="submit" disabled={!imageUrl ? disable : null} className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-gray-700 disabled:opacity-50">Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
export default UpdateBlog;
