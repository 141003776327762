import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doc, getDocs, collection, runTransaction } from 'firebase/firestore';
import { db } from '../firebase';


export const incrementVisitorCount = createAsyncThunk(
    'visitor/incrementVisitorCount',
    async () => {
        try {
            const currentDate = new Date();
            const currentMonth = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`;
            const visitorCountRef = doc(db, 'visitor', `count-${currentMonth}`);

            await runTransaction(db, async (transaction) => {
                const visitorCountDoc = await transaction.get(visitorCountRef);
                if (visitorCountDoc.exists()) {
                    const newCount = visitorCountDoc.data().count + 1;
                    transaction.update(visitorCountRef, { count: newCount });

                } else {
                    transaction.set(visitorCountRef, { count: 1 });

                }
            });
        } catch (error) {
            console.error('Error incrementing visitor count:', error);
            throw error;
        }
    }
);

export const getVisitorSuccess = visitor => ({
    type: 'visitor/getVisitorSuccess',
    payload: visitor
});
const initialState = {
    visitor: null,
    status: "idle",
    error: null,
};

export function getVisitorCount() {
    return async function (dispatch) {
        try {
            const visitorCountRef = collection(db, "visitor");
            const visitorCountDocs = await getDocs(visitorCountRef);

            const counts = {};
            visitorCountDocs.forEach((doc) => {
                const date = new Date(doc.id);
                const year = date.getFullYear();
                const month = date.toLocaleString('default', { month: 'long' });
                const count = doc.data().count;
                const key = `${month} ${year}`;
                if (counts[key]) {
                    counts[key] += count;
                } else {
                    counts[key] = count;
                }
            });


            dispatch(getVisitorSuccess(counts));

        } catch (error) {
            console.error("Error retrieving visitor count:", error);
            dispatch({
                type: "visitor/getVisitorCount/rejected",
                error: error.message,
            });
        }
    };
}


const visitorSlice = createSlice({
    name: "visitor",
    initialState,
    reducers: {
        startLoading: state => {
            state.isLoading = true;
        },
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        createPostSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.posts.push(action.payload);
        },
        getVisitorSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.visitor = action.payload;
        },
        getPostByUrlSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.post = action.payload;
        },
        getPostByIdSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.post = action.payload;
        },
        updatePostSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.posts = state.posts.map(post => post.id === action.payload.id ? action.payload : post);
            state.post = action.payload;
        },
        getPostByCategorySuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.posts = action.payload;
        },
        deletePostSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.post = state.posts.filter(post => post.id !== action.payload);
        },
        searchBlogsSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.searchResults = action.payload;
        },

    },
});

export default visitorSlice.reducer;
