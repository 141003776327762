import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteJob, getAppliedJobs } from '../../features/postsSlice';
import dateFormat from 'dateformat';
import { Link } from 'react-router-dom';
export const AppliedJobsTable = () => {
    const jobs = useSelector((state) => state.posts.jobs);
    const { user } = useSelector((state) => state.auth);
    const [gId, setGId] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!user || !user.userData || !user.userData.uid) {
            window.location.reload();
        } else {
            setGId(user.userData.uid);
            dispatch(getAppliedJobs());
        }
    }, [dispatch, user]);

    const handleDelete = useCallback(
        (did) => () => {
            dispatch(deleteJob(did));
        },
        [dispatch]
    );

    // const downloadFile = async (imageUrl) => {
    //     try {
    //         const storageRef = ref(storage, imageUrl);
    //         const downloadUrl = await getDownloadURL(storageRef);
    //         const link = document.createElement('a');
    //         link.href = downloadUrl;
    //         link.setAttribute('download', 'file');
    //         document.body.appendChild(link);
    //         link.click();
    //         document.body.removeChild(link);
    //     } catch (error) {
    //         console.log('Error downloading file:', error);
    //     }
    // };

    return (
        <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <div className="overflow-x-auto">
                <div className="align-middle inline-block min-w-full shadow-lg rounded-lg overflow-hidden">
                    <table className="w-full text-sm text-left  ">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
                            <tr>
                                <th className="px-6 py-3 border-b border-gray-600 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                                    Name
                                </th>
                                <th className="px-6 py-3 border-b border-gray-600 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                                    Email
                                </th>
                                <th className="px-6 py-3 border-b border-gray-600 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                                    Role
                                </th>
                                {/* <th className="px-6 py-3 border-b border-gray-600 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                                    CV
                                </th> */}
                                <th className="px-6 py-3 border-b border-gray-600 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                                    Date
                                </th>
                                <th className="px-6 py-3 border-b border-gray-600 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                                    Delete
                                </th>
                                <th className="px-6 py-3 border-b border-gray-600 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                                    CV
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {jobs.map((i) =>
                                i.userid === gId ? (
                                    <tr className="text-gray-900 bg-white border-b text-white" key={i.id}>
                                        <td className="px-6 py-4">
                                            <div className="flex items-center">
                                                <div className="ml-4">
                                                    <div className="text-sm leading-5 font-medium text-gray-900 ">
                                                        {i.name}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="text-sm leading-5 text-gray-900 ">{i.email}</div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="text-sm leading-5 text-gray-900 ">{i.url}</div>
                                        </td>
                                        {/* <td className="px-6 py-4">
                                            <div className="text-sm leading-5 text-gray-900 ">
                                                {i.imageUrl && (
                                                    <button className="text-blue-500 hover:underline" onClick={() => downloadFile(i.imageUrl)}>
                                                        Download File
                                                    </button>
                                                )}
                                            </div>
                                        </td> */}
                                        <td className="px-6 py-4 text-sm leading-5 text-gray-400">
                                            {dateFormat(i.createdAt, 'mmmm dS, yyyy')}
                                        </td>
                                        <td className="px-6 py-4">
                                            <button
                                                className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-400 text-gray-800"
                                                onClick={handleDelete(i.id)}
                                            >
                                                Delete
                                            </button>
                                        </td>
                                        <td className="px-6 py-4">
                                            <Link to={`${i.imageUrl}`} target="_blank" rel="noopener noreferrer">
                                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-400 text-gray-800">
                                                    Fullview
                                                </span>
                                            </Link>
                                        </td>
                                    </tr>
                                ) : null
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
