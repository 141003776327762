import React from 'react'
import aboutusbg from "../images/aboutusbg.webp"
export const Aboutus = () => {
    return (
        <div className="about-section mt-5" id='about-section' style={{ backgroundImage: `url(${aboutusbg})` }}>
            <div className="inner-container" >
                <h2 data-aos="fade-up" data-aos-delay="150">About Us</h2>
                <p className="text" data-aos="fade-up" data-aos-delay="200">
                    We are a mobile game developer and studio based in Lahore, Pakistan. With a team consisting of passionate and creative minds, our aim is to create mobile games that can be enjoyed by anyone! From developing high-quality mobile games to publishing the next hit title, we do everything in-house at our studio. Since the time we were founded in 2021, we published multiple mobiles games in the Hyper Casual genre.
                </p>
            </div>
        </div>
    )
}
